import { Box, Button, Typography } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
const ConfirmDelete = ({ text, onDelete, onClose, verb = "" }) => {
  return (
    <Box
      style={{
        background: "#FFFFFF",
        padding: 40,
        width: "100%",
      }}
    >
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontWeight: 700,
          color: "#282828",
          fontSize: 25,
          paddingBottom: 5,
        }}
      >
        <WarningAmberIcon /> Warning
      </Typography>
      <Typography
        style={{ fontFamily: "Montserrat", fontWeight: 500, color: "#282828" }}
      >
        Are you sure you want {verb ? verb : "to delete"} <b>{text}</b>?
      </Typography>
      <Box
        style={{
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "row",
          columnGap: 5,
        }}
      >
        <Button
          variant="outlined"
          style={{ borderColor: "black", color: "black", marginTop: 30 }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          style={{ background: "red", color: "white", marginTop: 30 }}
          onClick={onDelete}
        >
          Delete
        </Button>
      </Box>
    </Box>
  );
};
export default ConfirmDelete;
