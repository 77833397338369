import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import FormField from "../../../../CommonComponents/FormField";
import { Form, Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import { API_URL } from "../../../../api";
import { useNavigate } from "react-router";

const Profile = () => {
  const [resetPassword, setResetPassword] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const jsonString = localStorage.getItem("currentUser");
  const [currentUser, setCurrentUser] = useState(JSON.parse(jsonString));
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(currentUser);
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const refetch = () => {
    setLoading(true);
    axios
      .get(`${API_URL}/admins/${currentUser.id}`)
      .then((response) => {
        setUser(response.data);
        setLoading(false);
      })
      .catch();
  };

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("currentUser");
    !isLoggedIn ? navigate("/admin") : refetch();
  }, []);

  const initialValues = useMemo(
    () => ({
      email: user?.email,
      name: user?.name,
      DOB: user?.DOB,
    }),
    [user]
  );
  const handleSave = (event, values) => {
    setError('');

    if (values["password"] != values["confirm_password"]) {
      setError(`Passwords do not match`);
    } else {
      setLoading(true);
      axios
        .patch(`${API_URL}/admins/${currentUser.id}`, values, {})
        .then((response) => {
          setLoading(false);
          setEdit(false);
          setResetPassword(false);
          setUser(response.data);
          const jsonString = JSON.stringify(response.data);
          localStorage.setItem("currentUser", jsonString);
          refetch();
        })
        .catch((err) => {
          setLoading(false);
          setError('An error occured')
        });
    }
  };
  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress style={{ color: "black" }} />
        </div>
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={async (values) => {
            await new Promise((r) => setTimeout(r, 500));
            alert(JSON.stringify(values, null, 2));
          }}
        >
          {({ values }) => (
            <Form>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontFamily: "Montserrat",
                }}
              >
                Personal Information{" "}
              </Typography>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  onClick={() => setEdit(true)}
                  style={{ padding: 0, color: "black" }}
                >
                  Edit <EditIcon style={{ fontSize: 17 }} />
                </Button>
              </div>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <FormField
                    type="input"
                    name={`email`}
                    label="Email"
                    variant="outlined"
                    fullwidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    type="input"
                    name={`name`}
                    label="Name"
                    variant="outlined"
                    fullwidth
                    disabled={!isEdit}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    type="date"
                    name={`DOB`}
                    label="Date of birth"
                    variant="outlined"
                    fullwidth
                    disabled={!isEdit}
                  />
                </Grid>
              </Grid>

              <Button
                onClick={() => setResetPassword(true)}
                variant="outlined"
                style={{
                  color: "black",
                  fontFamily: "Montserrat",
                  borderColor: "black",
                  marginTop: 50,
                  marginBottom: 50,
                }}
              >
                Reset password
              </Button>

              {resetPassword && (
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      type="input"
                      name={`password`}
                      label="Password"
                      variant="outlined"
                      fullwidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      type="input"
                      name={`confirm_password`}
                      label="Confirm password"
                      variant="outlined"
                      fullwidth
                      required
                    />
                  </Grid>
                </Grid>
              )}
              {error && (
                <Typography style={{ marginTop: 3, marginBottom: -30, color: "red" }}>
                  {error}
                </Typography>
              )}
              {(isEdit || resetPassword) && (
                <Button
                  onClick={(e) => handleSave(e, values)}
                  variant="contained"
                  style={{
                    backgroundColor: "black",
                    fontFamily: "Montserrat",
                    borderColor: "black",
                    marginTop: 50,
                    marginBottom: 50,
                    marginLeft: 10,
                  }}
                >
                  Save
                </Button>
              )}
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};
export default Profile;
