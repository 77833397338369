import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import LogoutIcon from "@mui/icons-material/Logout";
import AdminPanelSettingsSharpIcon from "@mui/icons-material/AdminPanelSettingsSharp";
import DrawerRecord from "./Components/DrawerRecord/DrawerRecord";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import HandshakeIcon from "@mui/icons-material/Handshake";
import EngineeringIcon from "@mui/icons-material/Engineering";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CategoryIcon from "@mui/icons-material/Category";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import SubtitlesIcon from "@mui/icons-material/Subtitles";
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function AdminNavbar() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    document.title = "ALFA - Admin Portal";
    return () => {
      document.title = "ALFA - Metal Fabrication";
    };
  }, []);

  return (
    <Box>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        style={{ backgroundColor: "transparent", boxShadow: "none" }}
      >
        <Toolbar>
          <IconButton
            color="black"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon
              style={{ color: "black" }}
              sx={{
                ...(open && { display: "none" }),
              }}
            />
          </IconButton>
          {/* <Box
            sx={{
              mt: { xs: 0, md: 1 },
              ml: { xs: 0, md: 4 },
            }}
            maxWidth="xs"
          >
            <img
              // src={Logo}
              alt="logo"
              style={{
                width: "220px",
              }}
            />
          </Box> */}
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon
                style={{ color: "black" }}
                sx={{
                  ...(open && { display: "none" }),
                }}
              />
            ) : (
              <ChevronLeftIcon
                style={{ color: "black" }}
                sx={{
                  ...(!open && { display: "none" }),
                }}
              />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <DrawerRecord
            open={open}
            icon={<EmojiEventsIcon style={{ fontSize: "30px" }} />}
            title="Achievements"
            navigateTo="/admin/achievments"
          />
          <DrawerRecord
            open={open}
            icon={<HandshakeIcon style={{ fontSize: "30px" }} />}
            title="Clients"
            navigateTo="/admin/clients"
          />
          <DrawerRecord
            open={open}
            icon={<AdminPanelSettingsSharpIcon style={{ fontSize: "30px" }} />}
            title="Employees"
            navigateTo="/admin/employees"
          />
          <DrawerRecord
            open={open}
            icon={<EngineeringIcon style={{ fontSize: "30px" }} />}
            title="Projects"
            navigateTo="/admin/projects"
          />
          <DrawerRecord
            open={open}
            icon={<ShoppingCartIcon style={{ fontSize: "30px" }} />}
            title="Products"
            navigateTo="/admin/products"
          />
          <DrawerRecord
            open={open}
            icon={<CategoryIcon style={{ fontSize: "30px" }} />}
            title="Categories"
            navigateTo="/admin/categories"
          />
          <DrawerRecord
            open={open}
            icon={<SubtitlesIcon style={{ fontSize: "30px" }} />}
            title="Sub-Categories"
            navigateTo="/admin/subcategories"
          />
          <DrawerRecord
            open={open}
            icon={<LoyaltyIcon style={{ fontSize: "30px" }} />}
            title="Best sellers"
            navigateTo="/admin/bestSellers"
          />
        </List>
        <Divider />
        <List>
          <DrawerRecord
            open={open}
            icon={<PermIdentityIcon style={{ fontSize: "30px" }} />}
            title="Profile"
            navigateTo="/admin/profile"
          />
          <DrawerRecord
            open={open}
            icon={<LogoutIcon style={{ fontSize: "30px" }} />}
            title="Logout"
            navigateTo="/admin"
          />
        </List>
      </Drawer>
    </Box>
  );
}
