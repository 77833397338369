import { Grid, Typography } from "@mui/material";
import "../../../../../../CommonCss/styles.css";

const GridRecord = ({ primaryText, secondaryText, isEmail }) => {
  return (
    <Grid container style={{ marginBottom: 20 }}>
      <Grid item xs={4} md={4}>
        <Typography
          sx={{ fontSize: { xs: 10, md: 15 } }}
          style={{
            color: "white",
            fontFamily: "AdorHairline-Bold",
          }}
        >
          {primaryText}
        </Typography>
      </Grid>

      <Grid item xs={7}>
        {isEmail ? (
          <a href={`mailto:${[secondaryText]}`}>
            <Typography
              sx={{ fontSize: { xs: 15, md: 15 } }}
              style={{
                fontFamily: "Montserrat",
                color: "white",
                textDecoration: "underline",
                fontFamily: "AdorHairline-light",
              }}
            >
              {secondaryText}
            </Typography>
          </a>
        ) : (
          <Typography
            sx={{ fontSize: { xs: 15, md: 15 } }}
            style={{
              fontFamily: "Montserrat",
              color: "white",
              textDecoration: "underline",
              fontFamily: "AdorHairline-light",
            }}
          >
            {secondaryText}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};
export default GridRecord;
