import * as React from "react";
import Box from "@mui/material/Box";

import { CircularProgress, Grid, Typography } from "@mui/material";
import CategoryItem from "../Body/CategoryItem/CategoryItem";
import { useNavigate } from "react-router";
import axios from "axios";
import { API_URL } from "../../../../api";
import { useParams } from "react-router-dom";
import Footer from "../../../../CommonComponents/Footer";
import MainHeader from "../MainHeader/MainHeader";
import { Helmet } from "react-helmet-async";

export default function Subcategories() {
  const navigate = useNavigate();
  const { categoryName } = useParams();
  const [categories, setCategories] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    document.title = "ALFA - Collection";
    return () => {
      document.title = "ALFA - Metal Fabrication";
    };
  }, []);

  const refetch = () => {
    setLoading(true);
    axios
      .get(
        `${API_URL}/products/categories/${categoryName.replace(
          /-/g,
          " "
        )}/subcategories`
      )
      .then((response) => {
        setLoading(false);
        setCategories(response.data);
      })
      .catch(setLoading(false));
  };

  React.useEffect(() => {
    refetch();
  }, [categoryName]);

  React.useEffect(() => {
    const defaultMetaDescription = document.querySelector(
      'meta[name="description"]'
    );
    if (defaultMetaDescription) {
      defaultMetaDescription.remove();
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>ALFA - Collection - {categoryName}</title>
        <meta
          name="description"
          content={`Enhance your space with our premium selection of ${categoryName} thoughtfully designed to combine style, comfort, and durability. Ideal for both indoor and outdoor settings, check wide range of ${categories
            .map((category) => category.name)
            .join(", ")}`}
        />
        <meta
          name="keywords"
          content={`furnishing, alfa egypt, premium furniture, ${categoryName}, ${categories
            .map((category) => category.name)
            .join(", ")}`}
        />
        <link
          rel="canonical"
          href={`https://www.alfa-egypt.com/categories/${categoryName.replace(
            /\s+/g,
            "-"
          )}/subcategories?hasSubCategories=true`}
        />
      </Helmet>
      <MainHeader imageRemoved={true} />

      <Box
        sx={{
          paddingLeft: { xs: 3, md: 15 },
          paddingRight: { xs: 3, md: 15 },
          paddingTop: { xs: 5, md: 6 },
        }}
      >
        <h1
          style={{
            color: "black",
            fontFamily: "AdorHairline-Bold",
            marginTop: 1,
            paddingBottom: 2,
          }}
        >
          {categoryName}
        </h1>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress style={{ color: "black" }} />
          </div>
        ) : (
          <Grid container spacing={3}>
            {categories.map((category) => (
              <Grid
                item
                xs={12}
                sm={4}
                style={{
                  width: "70%",
                  cursor: "pointer",
                }}
                onClick={() =>
                  navigate(
                    `/Categories/${categoryName.replace(
                      /\s+/g,
                      "-"
                    )}/${category.name.replace(
                      /\s+/g,
                      "-"
                    )}?hasSubCategories=true`
                  )
                }
              >
                <CategoryItem category={category} />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>

      <Footer top={60} />
    </>
  );
}
