import { useEffect } from "react";
import MainHeader from "./Components/MainHeader";
import MainText from "./Components/MainText/MainText";
import Sectors from "./Components/Sectors";
import Footer from "../../CommonComponents/Footer";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Helmet } from "react-helmet-async";

const Furnishing = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const defaultMetaDescription = document.querySelector(
      'meta[name="description"]'
    );
    if (defaultMetaDescription) {
      defaultMetaDescription.remove();
    }
  }, []);
  return (
    <div style={{ backgroundColor: "#212121" }}>
      <Helmet>
        <title>ALFA - Furnishing</title>
        <meta
          name="description"
          content="Alfa's metal furniture for interiors is stylish, durable, and crafted from high-quality materials. We use premium-grade electrostatic powder coating metal and UV-treated ropes, sling fabrics, and texteline."
        />
        <meta
          name="keywords"
          content="furnishing, alfa egypt, premium-grade powder coating, UV-treated"
        />
        <link rel="canonical" href="https://www.alfa-egypt.com/Furnishing" />
      </Helmet>
      <MainHeader isSmallScreen={isSmallScreen} />
      <MainText isSmallScreen={isSmallScreen} />
      <Sectors isSmallScreen={isSmallScreen} />
      <Footer />
    </div>
  );
};
export default Furnishing;
