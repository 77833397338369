import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import furnishing_mob from "../../../../assets/images/home2_mob.jpeg";
// import furnishing from "../../../../assets/images/home_2.jpg";
import furnishing from "../../../../assets/images/FurnishingPage.jpeg";
import HeaderBar from "../../../../CommonComponents/HeaderBar/HeaderBar";

const MainHeader = ({ isSmallScreen }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const [breakPoint, setBreakPoint] = useState(window.innerWidth <= 1422);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 1422) setBreakPoint(true);
      else {
        setBreakPoint(false);
      }
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div style={{ position: "relative", maxWidth: "100%" }}>
      <Box
        style={{
          width: "100%",
          display: "flex",
        }}
      >
        <img
          alt="Furnishing Header"
          src={isSmallScreen ? furnishing_mob : furnishing}
          style={{
            width: "100%",
            height: "auto",
          }}
        />
      </Box>
      <div
        id="myElement"
        style={{
          position: "absolute",
          top: isVisible ? "45%" : "100%",
          transform: "translate(-50%, -50%)",
          color: "white",
          fontFamily: "AdorHairline-Bold",
          opacity: isVisible ? 1 : 0,
          animation: isVisible ? "moveUp 1s forwards" : "none",
          left: isSmallScreen ? "50%" : "25%",
        }}
      ></div>
      <div
        style={{
          position: "absolute",
          top: "0%",
          paddingLeft: breakPoint ? "3%" : "10%",
          color: "white",
          paddingBottom: "1%",
          backgroundColor: "black",
          fontFamily: "AdorHairline-Bold",
          maxWidth: "100%",
          overflowX: "hidden",
        }}
      >
        <HeaderBar />
      </div>
    </div>
  );
};
export default MainHeader;
