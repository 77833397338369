import { Box, Divider } from "@mui/material";

import FeaturedProjects from "./Components/FeaturedProjects/FeaturedProjects";
import Achievments from "./Components/Achievments";
import Services from "./Components/Services/Services";
import Clients from "./Components/Clients/Clients";
import MainTexts from "./Components/MainTexts";
import MainHeader from "./Components/MainHeader";
import { useState, memo, useEffect } from "react";
import Footer from "../../CommonComponents/Footer";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Helmet } from "react-helmet-async";

const Home = () => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleImageLoad = () => {
    setImageLoaded(true);
  };
  useEffect(() => {
    const defaultMetaDescription = document.querySelector(
      'meta[name="description"]'
    );
    if (defaultMetaDescription) {
      defaultMetaDescription.remove();
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>ALFA Egypt</title>
        <meta
          name="description"
          content="Established in 1970, Alfa Metal Fabrication & Furnishings is a subsidiary of Ayad Group, a reputable organization with a heritage of over 70 years of experience in steel industry."
        />
        <meta
          name="keywords"
          content="home, metal fabrication, furnishing, alfa egypt"
        />
        <link rel="canonical" href="https://www.alfa-egypt.com" />
      </Helmet>
      <MainHeader handleImageLoad={handleImageLoad} imageLoaded={imageLoaded} />
      {imageLoaded && (
        <>
          <MainTexts isSmallScreen={isSmallScreen} />

          <Services isSmallScreen={isSmallScreen} />

          <FeaturedProjects isSmallScreen={isSmallScreen} />
          <Achievments isSmallScreen={isSmallScreen} />
          <Box
            sx={{
              paddingLeft: { xs: 0, md: 28 },
              paddingRight: { xs: 0, md: 28 },
              marginTop: { xs: 2, md: 10 },
            }}
          >
            <Divider style={{ backgroundColor: "black" }} />
          </Box>
          <Clients isSmallScreen={isSmallScreen} />
          <Footer />
        </>
      )}
    </>
  );
};
export default memo(Home);
