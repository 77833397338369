import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_URL } from "../../../../../../../../api";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router";

const RelatedProducts = () => {
  let { productId } = useParams();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${API_URL}/products/bestSellers`)
      .then((response) => {
        setProducts(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [productId]);
  return loading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularProgress style={{ color: "black" }} />
    </div>
  ) : (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: 2,
          marginTop: 10,
        }}
      >
        <h3 style={{ fontFamily: "AdorHairline-Bold", fontSize: 24 }}>
          Best sellers
        </h3>
        <Divider
          style={{
            backgroundColor: "#86ab1c",
            width: 50,
            padding: 1,
            marginTop: 20,
            marginBottom: 20,
          }}
        />
      </div>
      <Grid
        container
        direction={isSmallScreen ? "column" : "row"}
        justifyContent={isSmallScreen ? "center" : "space-evenly"}
        alignItems={isSmallScreen ? "center" : "stretch"}
      >
        {products.map((product) => (
          <Grid item xs={6} sm={3} md={3}>
            <Card
              sx={{
                width: isSmallScreen ? "100%" : 300,
                boxShadow: "none",
                borderRadius: 0,
                cursor: "pointer",
              }}
              onClick={() => {
                window.scrollTo(0, 0);
                navigate(
                  `/collection/${product.id}/${product.name.replace(
                    /\s+/g,
                    "-"
                  )}`
                );
              }}
            >
              <CardContent style={{ paddingLeft: 0 }}>
                <Box
                  item
                  style={{
                    border: "1px solid #ECECEC",
                    width: 250,
                    height: 200,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={product?.images[0]?.image}
                    style={{
                      width: "70%",
                      maxHeight: "100%",
                    }}
                    alt={product.name}
                  />
                </Box>
                <h3
                  style={{
                    fontFamily: "AdorHairline-Bold",
                    fontSize: 15,
                  }}
                >
                  {product.name}
                </h3>
                {/* <h5
                  style={{
                    fontWeight: 200,
                    fontSize: 14,
                    color: "grey",
                  }}
                >
                  EGP {product.price}
                </h5> */}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
export default RelatedProducts;
