import { Box, Button, Dialog } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Mailto from "../../../../CommonComponents/Mailto";
import { useEffect, useMemo, useState } from "react";
import AddEmployee from "./AddEmployee/AddEmployee";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDelete from "../../../../CommonComponents/ConfirmDelete";
import axios from "axios";
import { API_URL } from "../../../../api";
import { useNavigate } from "react-router";

const Employees = () => {
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [openAddEmployee, setOpenAddEmployee] = useState(false);

  const [currentRow, setCurrentRow] = useState({});
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const allUserEmails = useMemo(() => {
    return rows?.map((row) => row.email);
  }, [rows]);

  const refetch = () => {
    setLoading(true);
    axios
      .get(`${API_URL}/admins/`)
      .then((response) => {
        setLoading(false);
        setRows(response.data);
      })
      .catch();
  };

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("currentUser");
    !isLoggedIn ? navigate('/admin') : refetch();
  }, []);

  const handleDeleteAdmin = async (event, values) => {
    event.preventDefault();
    setLoading(true);
    axios
      .delete(`${API_URL}/admins/${currentRow.id}`)
      .then((response) => {
        setLoading(false);
        refetch();
        setOpenConfirmDelete(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  return (
    <TableContainer>
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "flex-start", md: "flex-end" },
          flexDirection: "row",
          columnGap: 2,
        }}
      >
        <Button
          variant="outlined"
          style={{ color: "black", borderColor: "black" }}
          onClick={() => setOpenAddEmployee(true)}
        >
          Add
        </Button>
        <Mailto emails={allUserEmails?.join()}>
          <Button
            variant="outlined"
            style={{ color: "black", borderColor: "black" }}
          >
            Email all
          </Button>
        </Mailto>
      </Box>

      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: "bold", fontFamily: "Montserrat" }}>
              Name
            </TableCell>
            <TableCell
              align="left"
              style={{ fontWeight: "bold", fontFamily: "Montserrat" }}
            >
              Email
            </TableCell>
            <TableCell
              align="left"
              style={{ fontWeight: "bold", fontFamily: "Montserrat" }}
            >
              Date of birth
            </TableCell>
            <TableCell
              align="left"
              style={{ fontWeight: "bold", fontFamily: "Montserrat" }}
            ></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row, index) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                style={{ fontWeight: "bold", fontFamily: "Montserrat" }}
              >
                {row.name}
              </TableCell>
              <TableCell
                style={{ fontFamily: "Montserrat", fontWeight: 500 }}
                align="left"
              >
                {row.email}
              </TableCell>
              <TableCell
                style={{ fontFamily: "Montserrat", fontWeight: 500 }}
                align="left"
              >
                {row.DOB || "-"}
              </TableCell>
              <TableCell
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                }}
                align="left"
              >
                <Button
                  onClick={() => {
                    setOpenConfirmDelete(true);
                    setCurrentRow(row);
                  }}
                >
                  <DeleteIcon style={{ color: "red" }} />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Dialog
        onClose={() => setOpenConfirmDelete(false)}
        open={openConfirmDelete}
      >
        <ConfirmDelete
          onClose={() => setOpenConfirmDelete(false)}
          text={
            currentRow.firstName +
            " " +
            currentRow.lastName +
            "'s admin account"
          }
          onDelete={(e) => handleDeleteAdmin(e)}
        />
      </Dialog>
      <Dialog onClose={() => setOpenAddEmployee(false)} open={openAddEmployee}>
        <AddEmployee
          refetch={refetch}
          setOpenAddEmployee={setOpenAddEmployee}
        />
      </Dialog>
    </TableContainer>
  );
};
export default Employees;
