import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import Projects from "../Projects";
import axios from "axios";
import { API_URL } from "../../../../api";
import { useParams } from "react-router";
const categories = [
  "All",
  "Hospitality",
  "Mockups",
  "Commerical",
  "Clubhouse",
  "Residential",
];
const Sectors = ({ isSmallScreen }) => {
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const { category } = useParams();
  const [selectedCategory, setSelectedCategory] = useState(category || "All");

  const [searchTerm, setSearchTerm] = useState(category || "All");

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${API_URL}/projects/category/?category=Furnishing`)
      .then((response) => {
        setLoading(false);
        setInitialData(response.data);
        setProjects(response.data);
        category && setSearchTerm(category);
      })
      .catch();
  }, []);

  useEffect(() => {
    if (searchTerm === "All") setProjects(initialData);
    else {
      const projects = initialData;
      const filtered = projects.filter((item) =>
        item?.subCategory?.includes(searchTerm)
      );
      setProjects(filtered);
    }
  }, [searchTerm, category, initialData]);
  return (
    <Box
      style={{
        alignItems: !isSmallScreen && "center",
        justifyContent: !isSmallScreen && "center",
        display: "flex",
        paddingTop: 0,
        flexDirection: "column",
        backgroundColor: "#212121",
        paddingBottom: 70,
      }}
      id="furnishing-projects"
    >
      <h2
        style={{
          fontFamily: "AdorHairline-Bold",
          fontSize: isSmallScreen ? 28 : 32,
          color: "#A8A194",
          paddingLeft: isSmallScreen && 25,
        }}
      >
        Sectors We Serve.{" "}
      </h2>

      <Grid
        container
        direction="row"
        justifyContent={isSmallScreen ? "flex-start" : "center"}
        alignItems={isSmallScreen ? "flex-start" : "center"}
        style={{ paddingLeft: isSmallScreen && 25 }}
      >
        {categories.map((category, index) => (
          <Grid item xs={4} sm={2} md={1} index={index}>
            <h3
              style={{
                fontWeight: 300,
                textAlign: !isSmallScreen && "center",
                marginTop: 1,
                lineHeight: 1.5,
                cursor: "pointer",
                color: selectedCategory === category ? "#A99E8D" : "white",
                fontFamily: "AdorHairline-light",
              }}
              onClick={() => {
                setSearchTerm(category);
                setSelectedCategory(category);
              }}
            >
              {category}
            </h3>
          </Grid>
        ))}
      </Grid>
      <Projects projects={projects} isSmallScreen={isSmallScreen} />
    </Box>
  );
};
export default Sectors;
