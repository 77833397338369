import { CircularProgress } from "@mui/material";
import MainHeader from "./Components/MainHeader/MainHeader";
import { useEffect, useState } from "react";
import Footer from "../../CommonComponents/Footer";
import axios from "axios";
import { API_URL } from "../../api";
import Body from "./Components/Body/Body";
import { Helmet } from "react-helmet-async";

const Collection = () => {
  const [categories, setCategory] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const defaultMetaDescription = document.querySelector(
      'meta[name="description"]'
    );
    if (defaultMetaDescription) {
      defaultMetaDescription.remove();
    }
  }, []);

  const refetch = () => {
    setLoading(true);
    axios
      .get(`${API_URL}/products/categories/subcategories`)
      .then((response) => {
        setLoading(false);
        setCategory(response.data);
      })
      .catch();
  };

  useEffect(() => {
    refetch();
  }, []);
  return (
    <>
      <Helmet>
        <title>ALFA - Collection</title>
        <meta
          name="description"
          content="Discover Alfa Egypt’s curated collection of premium furniture, featuring elegant sofas, pergolas, two-seater and three-seater sets, sophisticated umbrellas, and finely crafted tables and chairs. Designed to elevate both indoor and outdoor spaces, Alfa Egypt’s range combines quality, durability, and style to meet the highest standards in luxury furnishings."
        />
        <meta
          name="keywords"
          content="furnishing, alfa egypt, premium furniture, pergolas, two-seater sofa, three-seater sofa, umbrellas, chairs, tables, sets"
        />
        <link rel="canonical" href="https://www.alfa-egypt.com/Categories" />
      </Helmet>
      <MainHeader imageRemoved={true} />
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress style={{ color: "black" }} />
        </div>
      ) : (
        <Body categories={categories} />
      )}
      <Footer top={60} />
    </>
  );
};
export default Collection;
