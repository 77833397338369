import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import Footer from "../../CommonComponents/Footer";
import HeaderBar from "../../CommonComponents/HeaderBar/HeaderBar";

const Section = ({ title, content }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", rowGap: 10 }}>
      <h1
        style={{
          fontFamily: "AdorHairline-Bold",
          fontSize: 25,
          color: "#000000",
        }}
      >
        {title}
      </h1>
      <h3
        style={{
          fontFamily: "AdorHairline-light",
          fontSize: 20,
          color: "#000000",
        }}
      >
        {content}
      </h3>
    </div>
  );
};
const PrivacyPolicy = () => {
  useEffect(() => {
    document.title = "ALFA - Privacy Policy";
    return () => {
      document.title = "ALFA - Metal Fabrication";
    };
  }, []);

  const [breakPoint, setBreakPoint] = useState(window.innerWidth <= 1422);
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 1422) setBreakPoint(true);
      else {
        setBreakPoint(false);
      }
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: 40,
        }}
        sx={{
          paddingLeft: { xs: 2, sm: 15 },
          paddingRight: { xs: 2, sm: 40 },
          marginTop: { xs: 10, sm: 20 },

        }}
      >
        <Section
          title="Privacy Policy"
          content="We use cookies to enhance your browsing experience, serve
         personalized ads or content, and analyze our traffic. Below, you'll
         find detailed information about the cookies we use and their
         respective categories."
        />
        <Section
          title="Necessary Cookies"
          content="Certain cookies are classified as 'Necessary' and are essential for
          the core functionality of the website. These cookies are stored on
          your browser to enable basic site features and ensure smooth
          operation."
        />
        <Section
          title="Third-Party Cookies"
          content="We also employ third-party cookies to analyze your usage of our
          website, remember your preferences, and deliver relevant content and
          advertisements. These cookies are only stored with your consent."
        />{" "}
        <Section
          title="Managing Cookies"
          content=" You have the option to enable or disable some or all cookies. Please
          note that disabling certain cookies may impact your browsing
          experience."
        />
      </Box>

      <div
        style={{
          position: "absolute",
          top: "0%",
          paddingLeft: breakPoint ? "3%" : "10%",
          color: "white",
          paddingBottom: "1%",
          backgroundColor: "black",
          fontFamily: "AdorHairline-Bold",
          maxWidth: "100%",
          overflowX: "hidden",
        }}
      >
        <HeaderBar />
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
