import { Divider, Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import InstagramIcon from "@mui/icons-material/Instagram";

const ShortDetails = ({ product, isSmallScreen }) => {
  const navigate = useNavigate();

  return (
    <Grid
      item
      xs={12}
      sm={5}
      sx={{ paddingLeft: { xs: 0, sm: 3 } }}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <h1
        style={{
          fontFamily: "AdorHairline-Bold",
          margin: 0,
          paddingTop: 10,
          fontSize: 24,
        }}
      >
        {product.name}
      </h1>

      <h2
        style={{
          fontFamily: "AdorHairline-Bold",
          margin: 0,
          color: "grey",
          fontWeight: 200,
          marginTop: 20,
          fontSize: 13.28,
        }}
      >
        {product.shortDescription}
      </h2>
      <div
        style={{
          display: "flex",
          flexDirection: "row",

          columnGap: 20,
          marginTop: 10,
        }}
      >
        <h3
          style={{
            fontFamily: "AdorHairline-Bold",
            margin: 0,
            fontWeight: 300,
            fontSize: 13.28,
          }}
        >
          Colors:
        </h3>
        <div style={{ display: "flex", alignItems: "center" }}>
          {product?.colors?.map((color, index) => (
            <div
              key={index}
              style={{
                width: "18px",
                height: "18px",
                borderRadius: "50%",
                backgroundColor: color?.hexacode,
                marginRight: "8px",
              }}
            ></div>
          ))}
        </div>
        <br />
      </div>
      <h3
        style={{
          fontFamily: "AdorHairline-Bold",
          marginTop: 20,
          color: "grey",
          fontSize: 16,
        }}
      >
        {product.price === 0 || !product.price
          ? ""
          : "EGP " + product?.price?.toLocaleString()}
      </h3>
      {!isSmallScreen && (
        <>
          <Divider sx={{ marginTop: { xs: 3, sm: 3 } }} />

          <h3
            style={{
              fontFamily: "AdorHairline-Bold",
              margin: 0,
              fontWeight: 300,
              display: "flex",
              flexDirection: "row",
              columnGap: 5,
              marginTop: 15,
              marginBottom: 10,
              fontSize: 13.28,
            }}
          >
            Category:
            <p
              style={{
                fontWeight: 200,
                color: "grey",
                cursor: "pointer",
                textDecoration: "underline",
              }}
              onClick={() => navigate(`/Categories/${product.category}`)}
            >
              {product.category}
            </p>
            {product.subcategory ? (
              <div
                style={{ display: "flex", flexDirection: "row", columnGap: 5 }}
              >
                <p> -</p>

                <p
                  style={{
                    fontWeight: 200,
                    color: "grey",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={() =>
                    navigate(
                      `/Categories/${product.category.replace(
                        /\s+/g,
                        "-"
                      )}/${product.subcategory.replace(
                        /\s+/g,
                        "-"
                      )}/?hasSubCategories=true`
                    )
                  }
                >
                  {product.subcategory}
                </p>
              </div>
            ) : (
              ""
            )}
          </h3>
        </>
      )}
    </Grid>
  );
};
export default ShortDetails;
