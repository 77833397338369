import { useEffect, useState } from "react";
import Footer from "../../CommonComponents/Footer";
import ContactDetails from "./Components/ContactDetails/ContactDetails";
import MainHeader from "./Components/MainHeader/MainHeader/MainHeader";
import { Helmet } from "react-helmet-async";

const Contact = () => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };
  useEffect(() => {
    document.title = "ALFA - Contact";
    return () => {
      document.title = "ALFA - Metal Fabrication";
    };
  }, []);
  return (
    <div style={{ backgroundColor: "black" }}>
      <Helmet>
        <meta
          name="description"
          content="We’re always for your business.
          Reach out to our experts and request a meeting and tell us about your project, we’ll get back to you with a suitable meeting date or a proposal. You can also give us a call or send us an email on the contacts below."
        />
        <meta name="keywords" content="alfa egypt, contact" />
        <link rel="canonical" href="https://www.alfa-egypt.com/Contact-us" />
      </Helmet>
      <MainHeader handleImageLoad={handleImageLoad} />
      {imageLoaded && <ContactDetails />}
      <Footer top={70} />
    </div>
  );
};
export default Contact;
