import { Box, CircularProgress, Grid } from "@mui/material";
import MainHeader from "../../Components/MainHeader/MainHeader";
import ProductItemList from "../../Components/ProductItemList";
import { useEffect, useState } from "react";
import Footer from "../../../../CommonComponents/Footer";
import axios from "axios";
import { API_URL } from "../../../../api";
import { useLocation, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const CategoryProducts = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const { categoryName, subCategoryName } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const hasSubCategories =
    searchParams.get("hasSubCategories") === "true" ? true : false;

  const refetch = () => {
    setLoading(true);
    let url;
    if (hasSubCategories && subCategoryName) {
      url = `products/subcategories/products/${subCategoryName.replace(
        /-/g,
        " "
      )}`;
    } else if (hasSubCategories) {
      url = `products/categories/${categoryName.replace(
        /-/g,
        " "
      )}/subcategories`;
    } else {
      url = `products/categories/products/${categoryName.replace(/-/g, " ")}`;
    }
    axios
      .get(`${API_URL}/${url}`)
      .then((response) => {
        setLoading(false);
        setProducts(response.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching products:", error);
      });
  };

  useEffect(() => {
    refetch();
  }, [categoryName, hasSubCategories, subCategoryName]);

  useEffect(() => {
    const defaultMetaDescription = document.querySelector(
      'meta[name="description"]'
    );
    if (defaultMetaDescription) {
      defaultMetaDescription.remove();
    }
  }, []);
  return (
    <>
      <Helmet>
        <title>
          ALFA - Collection - {categoryName} -{" "}
          {subCategoryName ? subCategoryName : ""}
        </title>
        <meta
          name="description"
          content={`Enhance your space with our premium selection of ${
            subCategoryName
              ? subCategoryName + "from our " + categoryName
              : categoryName
          } collection, thoughtfully designed to combine style, comfort, and durability. Ideal for both indoor and outdoor settings, our ${categoryName} are crafted from high-quality materials to provide lasting beauty and functionality, making them the perfect addition to any decor.`}
        />
        <meta
          name="keywords"
          content={`furnishing, alfa egypt, premium furniture, ${categoryName}, ${
            subCategoryName ? subCategoryName : categoryName + " collection"
          }`}
        />
        <link
          rel="canonical"
          href={`https://www.alfa-egypt.com/categories/${categoryName.replace(
            /\s+/g,
            "-"
          )}/${
            subCategoryName
              ? subCategoryName.replace(/\s+/g, "-") + "?hasSubCategories=true"
              : ""
          }`}
        />
      </Helmet>
      <MainHeader imageRemoved={true} />
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress style={{ color: "black" }} />
        </div>
      ) : (
        <Box
          sx={{
            paddingLeft: { xs: 3, md: 15 },
            paddingRight: { xs: 3, md: 15 },
            paddingTop: { xs: 2, md: 6 },
          }}
        >
          <h1
            style={{
              fontFamily: "AdorHairline-Bold",
              marginBottom: 10,
              marginLeft: 10,
            }}
          >
            {categoryName}
          </h1>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {products.map((product, index) => (
              <Grid item xs={12} sm={6} md={4}>
                <ProductItemList
                  product={product}
                  index={index}
                  isSubCategory={!subCategoryName && hasSubCategories}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      <Footer />
    </>
  );
};
export default CategoryProducts;
