import { Box } from "@mui/material";
import productdetailsHeader from "../../../../../../assets/images/productDetails.jpeg";
import productdetailsHeaderMob from "../../../../../../assets/images/productDetailsmob.jpeg";

import HeaderBar from "../../../../../../CommonComponents/HeaderBar/HeaderBar";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useState } from "react";
const MainHeader = ({ handleImageLoad }) => {
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [breakPoint, setBreakPoint] = useState(window.innerWidth <= 1422);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 1422) setBreakPoint(true);
      else {
        setBreakPoint(false);
      }
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      {/* <div style={{ position: "relative", maxWidth: "100%" }}> */}
      {/* <Box
          style={{
            width: "100%",
            display: "flex",
          }}
        >
          <img
            onLoad={handleImageLoad}
            alt="productdetailsHeader"
            src={isSmallScreen ? productdetailsHeaderMob : productdetailsHeader}
            style={{
              width: "100%",
              height: "auto",
            }}
          />
        </Box> */}

      <div
        style={{
          // position: "absolute",
          top: "0%",
          paddingLeft: breakPoint ? "3%" : "10%",
          color: "white",
          paddingBottom: "1%",
          backgroundColor: "black",
          fontFamily: "AdorHairline-Bold",
          maxWidth: "100%",
          overflowX: "hidden",
        }}
      >
        <HeaderBar imageRemoved={true} />
      </div>
      {/* </div> */}
    </>
  );
};
export default MainHeader;
