import { Box, Card, CardContent, CardMedia, Grid } from "@mui/material";
import "../../../../CommonCss/styles.css";

const Projects = ({ projects, isSmallScreen }) => {
  return (
    <Box
      style={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        marginTop: isSmallScreen ? 20 : 40,
        paddingTop: isSmallScreen ? 20 : 60,
        flexDirection: "column",
        margin: 0,
      }}
      sx={{
        backgroundColor: "#212121",
        paddingBottom: { sx: 10, md: 10 },
      }}
    >
      <Grid
        container
        spacing={4}
        direction="row"
        justifyContent="center"
        sx={{
          paddingLeft: { xs: 0, md: 28 },
          paddingRight: { xs: 0, md: 28 },
        }}
      >
        {projects?.map((project) => (
          <Grid
            item
            xs={11}
            sm={6}
            md={projects.length > 3 ? 4 : projects.length === 2 ? 5 : 10}
          >
            <Card
              sx={{
                maxWidth: 400,
                backgroundColor: "#212121",
                boxShadow: "none",
                borderRadius: 0,
              }}
            >
              <CardMedia
                component="img"
                height="250"
                image={project?.image}
                alt=""
              />
              <CardContent style={{ paddingLeft: 0 }}>
                <h4
                  style={{
                    fontFamily: "AdorHairline-Bold",
                    fontSize: 20,
                    color: "#A8A194",
                  }}
                >
                  {project.title}
                </h4>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
export default Projects;
