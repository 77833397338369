import Footer from "../../CommonComponents/Footer";
import Clients from "../Home/Components/Clients/Clients";
import FeaturedProjects from "./Components/FeaturedProjects/FeaturedProjects";
import MachineryList from "./Components/Machinery/Components/MachineryList";
import Machinery from "./Components/Machinery/Machinery";
import MainHeader from "./Components/MainHeader";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Helmet } from "react-helmet-async";
import { useEffect } from "react";

const MetalFabrication = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const defaultMetaDescription = document.querySelector(
      'meta[name="description"]'
    );
    if (defaultMetaDescription) {
      defaultMetaDescription.remove();
    }
  }, []);
  return (
    <>
      <Helmet>
        <title>Alfa - Metal Fabrication</title>
        <meta
          name="description"
          content="At Alfa Metal Fabrication & Furnishings, our focus on cutting-edge machinery ensures top-quality products and services.Our skilled team can manufacture, fabricate, and install various metal products like gates, fences, shades, pergolas, and outdoor lighting based on provided designs."
        />
        <meta
          name="keywords"
          content="metal fabrication, alfa egypt,manifacture,fabricate"
        />
        <link
          rel="canonical"
          href="https://www.alfa-egypt.com/Metal-Fabrication"
        />
      </Helmet>
      <MainHeader isSmallScreen={isSmallScreen} />
      <Machinery isSmallScreen={isSmallScreen} />
      <MachineryList />
      <FeaturedProjects isSmallScreen={isSmallScreen} />
      <Clients isSmallScreen={isSmallScreen} />
      <Footer />
    </>
  );
};
export default MetalFabrication;
