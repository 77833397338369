import React, { useState } from "react";

const UploadImage = ({ setFieldValue, field }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
    setFieldValue(field, file);
  };

  return (
    <div style={{ maxWidth: "100%", marginTop: 20 }}>
      <input
        type="file"
        id="imageUpload"
        onChange={handleImageUpload}
        title="Logo"
        accept="image/*"
      />
      <br />
      {selectedImage && (
        <img
          src={URL.createObjectURL(selectedImage)}
          alt="Uploaded"
          style={{ maxWidth: "100%", marginTop: 20 }}
        />
      )}
    </div>
  );
};

export default UploadImage;
