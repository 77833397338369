import { Box } from "@mui/material";
import { Outlet } from "react-router";
import AdminNavbar from "../AdminNavbar";

const AdminLayout = ({ children }) => {
  return (
    <div>
      <AdminNavbar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          pl: { xs: 10, md: 15 },
          pr: { xs: 2, md: 15 },
          pt: { xs: 2, md: 5 },
          marginTop: { xs: 2, sm: 5 },
        }}
      >
        <Outlet />
      </Box>
    </div>
  );
};

export default AdminLayout;
