import React from "react";
import { ErrorMessage, Field } from "formik";
import TextField from "@mui/material/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const GenerateFormField = (
  type,
  name,
  label,
  variant,
  fullwidth,
  customizedStyling,
  helperText = "",
  required,
  customMargin,
  showPass,
  setShowPass,
  size,
  multiline = false,
  options,
  disabled,
  color,
  setFieldValue
) => {
  switch (type) {
    case "input":
      return (
        <Field name={name}>
          {(props) => {
            const { field } = props;
            return (
              <TextField
                sx={customizedStyling ? customizedStyling : ""}
                style={{ marginTop: customMargin }}
                required={required}
                id={name}
                label={label}
                fullWidth={fullwidth}
                variant={variant}
                helperText={helperText}
                size={size}
                multiline={multiline}
                rows={multiline && 4}
                disabled={disabled}
                {...field}
              />
            );
          }}
        </Field>
      );

    case "password":
      return (
        <Field name={name}>
          {(props) => {
            const { field } = props;
            return (
              <TextField
                style={{ margin: customMargin ? customMargin : 12 }}
                fullWidth={fullwidth}
                variant={variant}
                sx={customizedStyling}
                label={label}
                required={required}
                type={showPass ? "text" : "password"}
                name={name}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPass(!showPass)}>
                        {showPass ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...field}
              />
            );
          }}
        </Field>
      );
    case "date":
      return (
        <Field name={name}>
          {(props) => {
            const { field } = props;
            return (
              <TextField
                disabled={disabled}
                sx={customizedStyling ? customizedStyling : ""}
                required={required}
                id={name}
                label={label}
                type="date"
                name={name}
                fullWidth={fullwidth}
                variant={variant}
                InputLabelProps={{
                  shrink: true,
                }}
                {...field}
              />
            );
          }}
        </Field>
      );

    case "select":
      return (
        <Field name={name}>
          {(props) => {
            const { field } = props;
            return (
              <FormControl fullWidth={fullwidth}>
                <InputLabel
                  sx={{
                    color: "black",
                    "& label.Mui-focused": {
                      color: "black",
                    },
                    "& label.MuiInputLabel-formControl": {
                      color: "black",
                    },
                  }}
                >
                  {label}
                </InputLabel>
                <Select
                  required={required}
                  id={name}
                  label={label}
                  variant={variant}
                  helperText={helperText}
                  size={size}
                  value={name}
                  {...field}
                >
                  {options.map((option) => (
                    <MenuItem value={option.value}>{option.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          }}
        </Field>
      );

    case "checkbox":
      return (
        <label>
          <Field type="checkbox" name={name} style={{ margin: 1 }} />
          {label}
        </label>
      );

    case "image":
      return (
        <Field name={name}>
          {(props) => {
            const { field } = props;
            return (
              <input
                id={name}
                {...field}
                type="file"
                onChange={(event) => {
                  setFieldValue(name, event.currentTarget.files[0]);
                }}
              />
            );
          }}
        </Field>
      );

    default:
      return (
        <Field name={name}>
          {(props) => {
            const { field } = props;
            return (
              <TextField
                sx={customizedStyling ? customizedStyling : ""}
                style={{ margin: customMargin ? customMargin : 12 }}
                required={required}
                id={name}
                label={label}
                fullWidth={fullwidth}
                variant={variant}
                helperText={helperText}
                size={size}
                multiline={multiline}
                rows={multiline && 4}
                {...field}
              />
            );
          }}
        </Field>
      );
  }
};
const FormField = ({
  type,
  name,
  label,
  variant,
  fullwidth,
  customizedStyling,
  helperText,
  required,
  customMargin,
  showPass,
  setShowPass,
  size,
  multiline,
  options,
  errorMessage = true,
  disabled,
  color,
  setFieldValue,
}) => {
  return (
    <>
      {GenerateFormField(
        type,
        name,
        label,
        variant,
        fullwidth,
        customizedStyling,
        helperText,
        required,
        customMargin,
        showPass,
        setShowPass,
        size,
        multiline,
        options,
        disabled,
        color,
        setFieldValue
      )}
      {errorMessage && (
        <div style={{ color: "red", paddingLeft: 15, fontSize: 14 }}>
          <ErrorMessage name={name} />
        </div>
      )}
    </>
  );
};

export default FormField;
