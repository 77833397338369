import { Box, Typography } from "@mui/material";
import "./styles.css";
const CategoryItem = ({ category }) => {
  return (
    <div className="image-container">
      <Box
        style={{
          maxWidth: "100%",
          display: "flex",
          height: "350px",
        }}
        className="zoom-container"
      >
        <img
          alt={category?.name}
          src={category?.logo}
          style={{ width: "100%" }}
          className="zoom-image"
        />
      </Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <h2
          style={{
            color: "black",
            fontFamily: "AdorHairline-Bold",
            marginTop: 1,
            fontSize: "24px",
          }}
        >
          {category?.name}
        </h2>
      </Box>
    </div>
  );
};
export default CategoryItem;
