import { Box, Button, Dialog, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import AddCategory from "./Components/AddCategory/AddCategory";
import CategoryCard from "./Components/CategoryCard";
import axios from "axios";
import { API_URL } from "../../../../api";
import { useNavigate } from "react-router";

const Categories = () => {
  const [openAddCategory, setOpenAddCategory] = useState(false);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const refetch = () => {
    setLoading(true);
    axios
      .get(`${API_URL}/products/categories`)
      .then((response) => {
        setLoading(false);
        setRows(response.data);
      })
      .catch();
  };

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("currentUser");
    !isLoggedIn ? navigate('/admin') : refetch();
  }, []);

  return (
    <>
      {/* <Typography
        style={{ fontWeight: "bold", fontFamily: "Montserrat", fontSize: 40 }}
      >
        Categories
      </Typography> */}

      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "flex-start", md: "flex-end" },
          flexDirection: "row",
          columnGap: 2,
        }}
      >
        <Button
          variant="outlined"
          style={{ color: "black", borderColor: "black" }}
          onClick={() => setOpenAddCategory(true)}
        >
          Add
        </Button>
      </Box>

      <Grid container spacing={2}>
        {rows.map((category, index) => (
          <Grid item key={index} xs={12} md={4}>
            <CategoryCard category={category} refetch={refetch} />
          </Grid>
        ))}
      </Grid>
      <Dialog onClose={() => setOpenAddCategory(false)} open={openAddCategory}>
        <AddCategory
          refetch={refetch}
          setOpenAddCategory={setOpenAddCategory}
        />
      </Dialog>
    </>
  );
};
export default Categories;
