import { Box, Button, Dialog, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import AddCategory from "./Components/AddCategory/AddCategory";
import CategoryCard from "./Components/CategoryCard";
import axios from "axios";
import { API_URL } from "../../../../api";
import { useNavigate } from "react-router";

const Subcategories = () => {
  const [openAddCategory, setOpenAddCategory] = useState(false);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const refetch = () => {
    setLoading(true);
    axios
      .get(`${API_URL}/products/categories/subcategories`)
      .then((response) => {
        setLoading(false);
        setRows(response.data);
      })
      .catch();
  };

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("currentUser");
    !isLoggedIn ? navigate("/admin") : refetch();
  }, []);

  return (
    <>
      <Typography
        style={{
          fontWeight: "bold",
          fontFamily: "AdorHairline-Bold",
          fontSize: 25,
        }}
      >
        Subcategories
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "flex-start", md: "flex-end" },
          flexDirection: "row",
          columnGap: 2,
        }}
      >
        <Button
          variant="outlined"
          style={{ color: "black", borderColor: "black" }}
          onClick={() => setOpenAddCategory(true)}
        >
          Add
        </Button>
      </Box>
      <Box style={{ display: "flex", flexDirection: "column", rowGap: 20 }}>
        {rows.map(
          (category, index) =>
            category.subcategories.length > 0 && (
              <div index={index}>
                <Typography
                  style={{ fontFamily: "AdorHairline-Bold", fontSize: 20 }}
                >
                  {category.name}
                </Typography>
                <Grid container spacing={2}>
                  {category.subcategories.map((subcategory, index) => (
                    <Grid item key={index} xs={12} md={4}>
                      <CategoryCard
                        subcategory={subcategory}
                        refetch={refetch}
                      />
                    </Grid>
                  ))}
                </Grid>
              </div>
            )
        )}
      </Box>

      <Dialog onClose={() => setOpenAddCategory(false)} open={openAddCategory}>
        <AddCategory
          refetch={refetch}
          setOpenAddCategory={setOpenAddCategory}
        />
      </Dialog>
    </>
  );
};
export default Subcategories;
