import { Button } from "@mui/material";
import React, { useState } from "react";
import { ChromePicker } from "react-color";

const ColorPicker = ({ setFieldValue, index }) => {
  const [color, setColor] = useState("#000000");

  const handleChangeComplete = (newColor) => {
    setColor(newColor.hex);
  };
  const [isOpen, setIsOpen] = useState(true);

  const handleConfirm = () => {
    setFieldValue(`colors.${index}`, color);
    setIsOpen(false);
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        columnGap: 10,
        justifyContent: "flex-start",
        alignItems: "flex-start",
      }}
    >
      {isOpen && (
        <ChromePicker color={color} onChangeComplete={handleChangeComplete} />
      )}
      <div style={{ display: "flex", justifyContent: "flex-start" }}>
        <div
          style={{
            width: "25px",
            height: "25px",
            borderRadius: "50%",
            backgroundColor: color,
            marginRight: "8px",
          }}
        ></div>
        {isOpen && (
          <Button
            style={{ color: "black", borderColor: "black" }}
            onClick={handleConfirm}
            variant="outlined"
          >
            Choose
          </Button>
        )}
      </div>
    </div>
  );
};

export default ColorPicker;
