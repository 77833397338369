import axios from "axios";
// export const API_URL = "http://localhost:8000";
export const API_URL = "https://alfa-backend-6dc09bd57c34.herokuapp.com";

export const api = axios.create({
  baseURL: API_URL,
  headers: {
    Accept: "application/json",
  },
});
