import { Box, Button, Dialog, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import AddClient from "./Components/AddClient/AddClient";
import ClientCard from "./Components/ClientCard";
import axios from "axios";
import { API_URL } from "../../../../api";
import { useNavigate } from "react-router";

const Clients = () => {
  const [openAddClient, setOpenAddClient] = useState(false);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const refetch = () => {
    setLoading(true);
    axios
      .get(`${API_URL}/clients/`)
      .then((response) => {
        setLoading(false);
        setRows(response.data);
      })
      .catch();
  };

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("currentUser");
    !isLoggedIn ? navigate('/admin') : refetch();
  }, []);

  return (
    <>
      {/* <Typography
        style={{ fontWeight: "bold", fontFamily: "Montserrat", fontSize: 40 }}
      >
        Clients
      </Typography> */}

      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "flex-start", md: "flex-end" },
          flexDirection: "row",
          columnGap: 2,
        }}
      >
        <Button
          variant="outlined"
          style={{ color: "black", borderColor: "black" }}
          onClick={() => setOpenAddClient(true)}
        >
          Add
        </Button>
      </Box>

      <Grid container spacing={2}>
        {rows.map((client, index) => (
          <Grid item key={index} xs={12} md={4}>
            <ClientCard client={client} refetch={refetch} />
          </Grid>
        ))}
      </Grid>
      <Dialog onClose={() => setOpenAddClient(false)} open={openAddClient}>
        <AddClient refetch={refetch} setOpenAddClient={setOpenAddClient} />
      </Dialog>
    </>
  );
};
export default Clients;
