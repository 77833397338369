import { Box, Card, CardContent, CardMedia, Grid } from "@mui/material";
import "../../../../CommonCss/styles.css";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../../../../api";

const FeaturedProjects = ({ isSmallScreen }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${API_URL}/projects/category/?category=Metal fabrication`)
      .then((response) => {
        setLoading(false);
        setProjects(response.data);
      })
      .catch();
  }, []);
  return (
    <Box
      style={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        marginTop: 40,
        paddingTop: 60,
        flexDirection: "column",
        margin: 0,
      }}
      sx={{
        backgroundColor: "#F2F2F2",
        paddingBottom: { sx: 10, md: 10 },
      }}
    >
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent={!isSmallScreen && "center"}
        alignItems={!isSmallScreen && "center"}
        textAlign={!isSmallScreen && "center"}
        sx={{
          paddingLeft: { xs: 2.5, sm: 10, md: 0 },
          paddingRight: { xs: 0, sm: 15, md: 0 },
        }}
      >
        <Grid item xs={12} md={3.8}>
          <h3
            style={{
              fontFamily: "AdorHairline-Bold",
              paddingBottom: 30,
              fontSize: isSmallScreen ? 25 : 32,
            }}
          >
            Just Send Us The Design And We Take It From There
          </h3>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent={!isSmallScreen && "center"}
        alignItems={!isSmallScreen && "center"}
        style={{ paddingBottom: 60 }}
        sx={{
          paddingLeft: { xs: 2.5, sm: 10, md: 0 },
          paddingRight: { xs: 0, sm: 15, md: 0 },
        }}
      >
        <Grid item xs={12} md={6}>
          <h4
            style={{
              fontWeight: 300,
              textAlign: !isSmallScreen && "center",
              fontFamily: "AdorHairline-light",
              fontSize: isSmallScreen ? 21 : 24,
            }}
          >
            Our skilled team can manufacture, fabricate, and install various
            metal products like gates, fences, shades, pergolas, and outdoor
            lighting based on provided designs.
          </h4>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent={!isSmallScreen && "center"}
        alignItems={!isSmallScreen && "center"}
        textAlign={!isSmallScreen && "center"}
        sx={{
          paddingLeft: { xs: 2.5, sm: 0 },
        }}
      >
        <Grid item xs={12} md={3.8}>
          <h4
            style={{
              fontFamily: "AdorHairline-Bold",
              paddingBottom: 30,
              fontSize: isSmallScreen ? 30 : 32,
            }}
            id="metal-featured-projects"
          >
            Featured Projects
          </h4>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={isSmallScreen ? 0 : 4}
        direction="row"
        justifyContent="center"
        sx={{
          paddingLeft: { xs: 0, sm: 15, md: 28 },
          paddingRight: { xs: 0, sm: 15, md: 28 },
        }}
      >
        {projects?.map((project) => (
          <Grid item xs={11} sm={6} md={4}>
            <Card
              sx={{
                maxWidth: 400,
                backgroundColor: "#F2F2F2",
                boxShadow: "none",
                borderRadius: 0,
              }}
            >
              <CardMedia
                component="img"
                height="230"
                image={project?.image}
                alt=""
              />

              <CardContent style={{ paddingLeft: 0 }}>
                <h4
                  style={{
                    fontFamily: "AdorHairline-Bold",
                    fontSize: 20,
                  }}
                >
                  {project.title}
                </h4>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
export default FeaturedProjects;
