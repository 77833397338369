import { Box, Grid } from "@mui/material";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import "../../../../CommonCss/styles.css";
import { useEffect, useState, memo } from "react";
import axios from "axios";
import { API_URL } from "../../../../api";

const Clients = ({ isSmallScreen }) => {
  const matches = useMediaQuery("(max-width:600px)");
  const [rows, setRows] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_URL}/clients/`)
      .then((response) => {
        setRows(response.data);
      })
      .catch();
  }, []);

  return (
    <>
      <Grid
        container
        spacing={0}
        direction={matches ? "column" : "row"}
        justifyContent="center"
        alignItems="center"
        style={{ paddingBottom: 0, margin: 0 }}
      >
        <h4
          style={{
            fontFamily: "AdorHairline-Bold",
            paddingTop: isSmallScreen ? 30 : 100,
            fontSize: isSmallScreen ? 28 : 32,
          }}
        >
          OUR CLIENTS{" "}
        </h4>
      </Grid>
      <Grid
        container
        spacing={0}
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          paddingLeft: { xs: 1, md: 28 },
          paddingRight: { xs: 1, md: 28 },
        }}
      >
        {rows.map((client, index) => (
          <Grid item xs={6} md={3} key={index}>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: client.website_link && "pointer",
              }}
              onClick={() =>
                window.open(`https://${client.website_link}`, "_blank")
              }
            >
              <img
                alt={client?.name}
                src={client?.logo}
                style={{
                  width: "100%",
                  height: "auto",
                  margin: 0,
                  padding: 0,
                }}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
export default memo(Clients);
