import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import FormField from "../../../../../../CommonComponents/FormField";
import { Form, Formik } from "formik";
import { useMemo, useState } from "react";
import UpdateImage from "../../../../../../CommonComponents/UpdateImage/UpdateImage";
import axios from "axios";
import { API_URL } from "../../../../../../api";
import Loader from "../../../../../../CommonComponents/Loader/Loader";

const EditCategory = ({ category, setOpenEditCategory, refetch }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const initialValues = useMemo(
    () => ({
      name: category?.name,
    }),
    [category]
  );
  const handleEditCategory = async (event, values) => {
    setLoading(true);
    axios
      .patch(`${API_URL}/products/subcategories/${category.id}`, values, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        setOpenEditCategory(false);
        setLoading(false);
        refetch();
      })
      .catch((err) => {
        setLoading(false);
        err.message == "Network Error"
          ? setError(
              "Failed to Save ! Your images might be too large, compress them and try again."
            )
          : setError("Failed to Save !");
      });
  };
  return (
    <Box
      style={{
        background: "#FFFFFF",
        padding: 40,
        width: "100%",
      }}
    >
      {" "}
      <Formik initialValues={initialValues}>
        {({ values, setFieldValue }) => (
          <Form>
            <Typography
              style={{
                fontWeight: "bold",
                fontFamily: "Montserrat",
                marginBottom: 50,
              }}
            >
              Sub-Category details{" "}
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <FormField
                  type="input"
                  name={`name`}
                  label="name"
                  variant="outlined"
                  fullwidth
                  //   customizedStyling={customTextFieldStyling}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <UpdateImage
                  existingImage={values.logo}
                  setFieldValue={setFieldValue}
                  field="logo"
                />
              </Grid>
            </Grid>
            {error && (
              <Typography
                style={{ marginTop: 3, marginBottom: 3, color: "red" }}
              >
                {error}
              </Typography>
            )}
            <Button
              onClick={(event) => handleEditCategory(event, values)}
              variant="contained"
              style={{
                backgroundColor: "black",
                fontFamily: "Montserrat",
                borderColor: "black",
                marginTop: 50,
                marginBottom: 50,
                marginLeft: 10,
              }}
            >
              {loading ? (
                <CircularProgress style={{ color: "white" }} />
              ) : (
                "Save"
              )}
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
export default EditCategory;
