import { Box } from "@mui/material";
import { useState } from "react";
import Footer from "../../../../CommonComponents/Footer";
import RelatedProducts from "./Components/Details/Components/RelatedProducts";
import Details from "./Components/Details/Details";
import MainHeader from "./Components/MainHeader/MainHeader";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";

const Product = () => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleImageLoad = () => {
    setImageLoaded(true);
  };
  return (
    <>
      <MainHeader handleImageLoad={handleImageLoad} imageLoaded={imageLoaded} />
      <Details isSmallScreen={isSmallScreen} />
      <Box
        sx={{
          paddingLeft: { xs: 3, sm: 3, md: 20 },
          paddingRight: { xs: 3, sm: 3, md: 20 },
        }}
      >
        <RelatedProducts />
      </Box>
      <Footer />
    </>
  );
};
export default Product;
