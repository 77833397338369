import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useState } from "react";
const LongDetails = ({ product }) => {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box
      sx={{
        width: "100%",
        typography: "body1",
        paddingRight: { xs: 3, md: 57 },
        marginTop: { xs: 2, md: 5 },
      }}
    >
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
            sx={{ "& .MuiTabs-indicator": { backgroundColor: "#86ab1c" } }}
          >
            <Tab
              label="Description"
              value="1"
              style={{
                fontFamily: "AdorHairline-Bold",
                margin: 0,
              }}
              sx={{
                "&.Mui-selected": {
                  color: "black",
                },
              }}
            />
            <Tab
              label="Features"
              value="2"
              style={{ fontFamily: "AdorHairline-Bold", margin: 0 }}
              sx={{
                "&.Mui-selected": {
                  color: "black",
                },
              }}
            />
            <Tab
              label="Specs"
              value="3"
              style={{ fontFamily: "AdorHairline-Bold", margin: 0 }}
              sx={{
                "&.Mui-selected": {
                  color: "black",
                },
              }}
            />
          </TabList>
        </Box>
        <TabPanel
          value="1"
          style={{
            fontFamily: "AdorHairline-Bold",
            margin: 0,
            color: "grey",
            whiteSpace: "pre-line",
            fontWeight: 200,
          }}
        >
          <h3 style={{ fontSize: 13.28 }}>{product.longDescription}</h3>
        </TabPanel>
        <TabPanel
          value="2"
          style={{
            fontFamily: "AdorHairline-Bold",
            margin: 0,
            color: "grey",
            whiteSpace: "pre-line",
            fontWeight: 200,
          }}
        >
          <h3 style={{ fontSize: 13.28 }}>{product.features}</h3>
        </TabPanel>
        <TabPanel
          value="3"
          style={{
            fontFamily: "AdorHairline-Bold",
            margin: 0,
            color: "grey",
            whiteSpace: "pre-line",
            fontWeight: 200,
          }}
        >
          <h3 style={{ fontSize: 13.28 }}>{product.specs}</h3>
        </TabPanel>
      </TabContext>
    </Box>
  );
};
export default LongDetails;
