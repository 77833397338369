import { Button } from "@mui/material";
import React, { useState } from "react";

const UpdateImage = ({ existingImage, setFieldValue, field }) => {
  const [imageUrl, setImageUrl] = useState(existingImage);
  const fileInputRef = React.createRef();

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setFieldValue(field, file);
    const reader = new FileReader();

    reader.onload = () => {
      setImageUrl(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const triggerFileInput = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };

  return (
    <div>
      <Button
        onClick={triggerFileInput}
        style={{ color: "black", borderColor: "black", marginBottom: 10 }}
        variant="outlined"
      >
        Update Logo Image
      </Button>

      <img alt="" src={imageUrl} style={{ width: "100%" }} />

      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleImageUpload}
      />
    </div>
  );
};

export default UpdateImage;
