import React, { useEffect, useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { FieldArray, Form, Formik } from "formik";
import FormField from "../../../../../../../../CommonComponents/FormField";
import axios from "axios";
import { API_URL } from "../../../../../../../../api";
import { CircularProgress, Divider } from "@mui/material";
import { Box, Button } from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ColorPicker from "../../../../../../../../CommonComponents/ColorPicker/ColorPicker";

const customTextFieldStyling = {
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "black",
  },
};

const AddorEditProduct = ({
  isEdit = false,
  product,
  setopenEditProduct,
  setOpenAddProduct,
  refetch,
}) => {
  const [loading, setLoading] = useState(false);
  const [msg, setMessage] = useState(false);
  const [error, setError] = useState("");
  const [categorieslist, setCategoriesList] = useState([]);
  const [subcategorieslist, setSubcategoriesList] = useState([]);

  const initialValues = useMemo(
    () => ({
      name: isEdit ? product?.name : "",
      shortDescription: isEdit ? product?.shortDescription : "",
      longDescription: isEdit ? product?.longDescription : "",
      specs: isEdit ? product?.specs : "",
      features: isEdit ? product?.features : "",
      colors: isEdit ? product?.colors : [],
      price: isEdit ? product?.price : "",
      category: isEdit ? product?.categoryId : "",
      subcategory: isEdit ? product?.subcategoryId : "",
      images: isEdit ? product?.images : [],
    }),
    []
  );
  const handleAddProdject = async (event, values) => {
    setError('')
    event.preventDefault();
    const changedValues = {};
    Object.keys(values).forEach((key) => {
      if (values[key] !== initialValues[key]) {
        changedValues[key] = values[key];
      }
    });
    setLoading(true);
    if (isEdit) {
      axios
        .patch(`${API_URL}/products/${product.id}`, changedValues, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setLoading(false);
          setMessage("product added successfully");
          setopenEditProduct(false);
          refetch();
        })
        .catch((err) => {
          setLoading(false);
          err.message == "Network Error"
            ? setError(
                "Failed to Save ! Your images might be too large, compress them and try again."
              )
            : setError("Failed to Save !");
        });
    } else {
      axios
        .post(`${API_URL}/products/`, values, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setLoading(false);
          setMessage("product added successfully");
          setOpenAddProduct(false);
          refetch();
        })
        .catch((err) => {
          setLoading(false);
          err.message == "Network Error"
            ? setError(
                "Failed to Save ! Your images might be too large, compress them and try again."
              )
            : setError("Failed to Save !");
        });
    }
  };
  useEffect(() => {
    axios
      .get(`${API_URL}/products/categories/subcategories`)
      .then((response) => {
        setLoading(false);
        setCategoriesList(
          response.data.map((item) => ({
            label: item.name,
            value: item.id,
            subcategories: item.subcategories,
          }))
        );
      })
      .catch();
  }, []);
  return (
    <Box
      style={{
        background: "#FFFFFF",
        padding: 50,
        width: "100%",
      }}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          await new Promise((r) => setTimeout(r, 500));
          alert(JSON.stringify(values, null, 2));
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Typography
              style={{
                fontWeight: "bold",
                fontFamily: "Montserrat",
                fontSize: 25,
              }}
            >
              Product Details
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <FormField
                  type="input"
                  name={`name`}
                  label="Name"
                  variant="standard"
                  fullwidth
                  customizedStyling={customTextFieldStyling}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  type="select"
                  name={`category`}
                  label="Category"
                  variant="standard"
                  fullwidth
                  customizedStyling={customTextFieldStyling}
                  options={categorieslist}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  type="select"
                  name={`subcategory`}
                  label="Sub-Category"
                  variant="standard"
                  fullwidth
                  customizedStyling={customTextFieldStyling}
                  options={
                    categorieslist
                      ?.find((item) => item.value === values.category)
                      ?.subcategories?.map((item) => ({
                        label: item.name,
                        value: item.id,
                      })) || []
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  type="input"
                  name={`price`}
                  label="Price/EGP"
                  variant="standard"
                  fullwidth
                  customizedStyling={customTextFieldStyling}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FieldArray name="colors">
                  {({ remove, push }) => (
                    <div>
                      <Typography
                        variant="h7"
                        color="Black"
                        style={{ fontFamily: "Montserrat", marginBottom: 20 }}
                      >
                        Colors
                      </Typography>
                      <Button
                        style={{ color: "black" }}
                        onClick={() => push("")}
                      >
                        <AddCircleOutlineIcon />
                      </Button>
                      {values.colors?.length > 0 &&
                        values.colors?.map((color, index) => (
                          <Grid
                            container
                            spacing={0}
                            paddingBottom={3}
                            key={index}
                          >
                            <Grid item xs={12} sm={10}>
                              {color === "" ? (
                                <ColorPicker
                                  setFieldValue={setFieldValue}
                                  index={index}
                                  key={index}
                                />
                              ) : (
                                <div
                                  style={{
                                    width: "25px",
                                    height: "25px",
                                    borderRadius: "50%",
                                    backgroundColor: color?.hexacode
                                      ? color?.hexacode
                                      : color,
                                    marginRight: "8px",
                                  }}
                                ></div>
                              )}
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={1}
                              style={{
                                justifyConte: "center",
                                alignItems: "center",
                              }}
                            >
                              <Button
                                style={{ color: "black" }}
                                onClick={() => remove(index)}
                              >
                                <RemoveCircleOutlineIcon /> Remove
                              </Button>
                            </Grid>
                          </Grid>
                        ))}
                    </div>
                  )}
                </FieldArray>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormField
                  type="input"
                  name={`shortDescription`}
                  label="Short description"
                  variant="standard"
                  fullwidth
                  multiline
                  customizedStyling={customTextFieldStyling}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormField
                  type="input"
                  name={`longDescription`}
                  label="Long description"
                  variant="standard"
                  fullwidth
                  multiline
                  customizedStyling={customTextFieldStyling}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormField
                  type="input"
                  name={`specs`}
                  label="Specs"
                  variant="standard"
                  multiline
                  fullwidth
                  customizedStyling={customTextFieldStyling}
                />
              </Grid>{" "}
              <Grid item xs={12} sm={12}>
                <FormField
                  type="input"
                  name={`features`}
                  label="Features"
                  variant="standard"
                  fullwidth
                  multiline
                  customizedStyling={customTextFieldStyling}
                />
              </Grid>
              <Grid item xs={12}>
                <FieldArray name="images">
                  {({ remove }) => (
                    <div>
                      {values.images?.map((image, index) => (
                        <Box
                          key={index}
                          style={{ display: "flex", flexDirection: "start" }}
                        >
                          <img
                            style={{
                              width: 200,
                              height: 200,
                            }}
                            src={
                              !isEdit
                                ? URL.createObjectURL(image)
                                : image?.image || URL.createObjectURL(image)
                            }
                            alt={`Image ${index}`}
                          />
                          <Button
                            style={{ color: "black" }}
                            onClick={() => remove(index)}
                          >
                            <RemoveCircleOutlineIcon /> Remove
                          </Button>
                        </Box>
                      ))}
                      <input
                        type="file"
                        multiple
                        onChange={(event) => {
                          const files = Array.from(event.target.files);
                          setFieldValue("images", values.images?.concat(files));
                        }}
                      />
                    </div>
                  )}
                </FieldArray>
              </Grid>
            </Grid>
            {error && (
              <Typography
                style={{ marginTop: 3, marginBottom: 3, color: "red" }}
              >
                {error}
              </Typography>
            )}
            <Button
              type="submit"
              style={{ background: "black", color: "white", marginTop: 30 }}
              fullWidth
              onClick={(e) => handleAddProdject(e, values)}
            >
              {loading ? (
                <CircularProgress style={{ color: "white" }} />
              ) : (
                "Save"
              )}
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
export default AddorEditProduct;
