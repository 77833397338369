import { Box } from "@mui/material";
import ImageTextSideCards from "../ImageTextSideCards";
import esca from "../../../../assets/images/esca.webp";
import palmHills from "../../../../assets/images/palmhills.webp";
import monorail from "../../../../assets/images/monorail.webp";
import emaar from "../../../../assets/images/Emaar.webp";
import "../../../../CommonCss/styles.css";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {memo} from "react";

const FeaturedProjects = ({ isSmallScreen }) => {
  const matches = useMediaQuery("(max-width:600px)");

  return (
    <Box
      style={{
        alignItems: !isSmallScreen && "center",
        justifyContent: "center",
        display: "flex",
        marginTop: 80,
        flexDirection: "column",
        margin: 0,
      }}
      sx={{
        backgroundColor: { sx: "white", md: "#A8A194" },
        paddingBottom: { sx: 10, md: 20 },
        paddingTop: { xs: 5, md: 10 },
      }}
    >
      <h3
        style={{
          fontFamily: "AdorHairline-Bold",
          paddingBottom: !isSmallScreen && 30,
          fontSize: isSmallScreen ? 28 : 32,
          paddingLeft: isSmallScreen && 10,
        }}
      >
        Featured Projects
      </h3>
      <ImageTextSideCards
        title="Marassi Emaar Misr"
        description="Alfa clinched contracts for both phases of Marassi North Coast's fence project, totaling 4700 meters, along with 40 EMAAR-logoed aluminum panels."
        image={emaar}
        imageFirst={matches}
        isSmallScreen={isSmallScreen}
      />
      <ImageTextSideCards
        title="Esca Terrenal"
        description=" The dynamic counter bar at ESCA Terrenal is located at 5A by
              the Waterway Developments. Alfa is proud to have contributed
              to this visionary bar in collaboration with BADIE Architects
              and COSIGN EG."
        image={esca}
        imageFirst
        isSmallScreen={isSmallScreen}
      />
      <ImageTextSideCards
        title="Palm Hills Golf Central Mall"
        description="Solid Construction Co. selected Alfa to manufacture 6 different shades with a long span structures for the Golf Central Mall in Palm Hills."
        image={palmHills}
        imageFirst={matches}
        isSmallScreen={isSmallScreen}
      />

      <ImageTextSideCards
        title="Monorail Steel & Advertising Pillars"
        description="Alfa Metal Fabrication has been chosen to manufacture the pre-qualification stage of the structural steel used in Egypt's first monorail system. Additionally, we've successfully delivered advertising pillars, highlighting our versatility and capability in diverse fabrication projects."
        image={monorail}
        imageFirst
        isSmallScreen={isSmallScreen}
      />
    </Box>
  );
};
export default memo(FeaturedProjects);
