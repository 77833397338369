import { useEffect, useState } from "react";
import Footer from "../../CommonComponents/Footer";
import Main from "./Components/Main";
import MainHeader from "./Components/MainHeader/MainHeader";
import { Helmet } from "react-helmet-async";

const Electrostatic = () => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };
  useEffect(() => {
    document.title = "ALFA - Electrostatic";
    return () => {
      document.title = "ALFA - Metal Fabrication";
    };
  }, []);
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Alfa electrostatic powder coating line offers a high-quality paint solution. Our automated system provides an efficient coating process to ensure the finest coating for aluminum, stainless steel and carbon steel material. "
        />
        <meta
          name="keywords"
          content="alfa egypt, electrostatic, powder coating"
        />
        <link rel="canonical" href="https://www.alfa-egypt.com/Electrostatic" />
      </Helmet>
      <MainHeader handleImageLoad={handleImageLoad} imageLoaded={imageLoaded} />
      {imageLoaded && (
        <>
          <Main />
          <Footer top={80} />
        </>
      )}
    </>
  );
};
export default Electrostatic;
