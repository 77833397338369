import { Box, Grid } from "@mui/material";
import { useEffect, useState, memo } from "react";
import factory from "../../../../assets/images/1.png";
import capaciy from "../../../../assets/images/2.png";
import staff from "../../../../assets/images/3.png";
import projects from "../../../../assets/images/4.png";
import "../../../../CommonCss/styles.css";
import axios from "axios";
import { API_URL } from "../../../../api";
const achievments = [
  {
    image: factory,
    value: "10,000",
    title: "FACTORY TOTAL AREA",
    unit: "M²",
    backendMap: "factoryTotalArea",
  },
  {
    image: capaciy,
    value: "15,000",
    title: "PRODUCTION CAPACITY",
    unit: "TONS",
    backendMap: "productionCapacity",
  },
  {
    image: staff,
    value: "120",
    title: "STAFF PERSONNEL",
    unit: "",
    backendMap: "staffPersonnel",
  },
  {
    image: projects,
    value: "200",
    title: "PROJECTS CO",
    unit: <sup>+</sup>,
    backendMap: "projectsCo",
  },
];

const Achievments = ({ isSmallScreen }) => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_URL}/achievements/`)
      .then((response) => {
        setRows(response.data[0]);
      })
      .catch();
  }, []);

  return (
    <>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <h4
          style={{
            fontFamily: "AdorHairline-Bold",
            paddingTop: isSmallScreen ? 30 : 100,
            paddingBottom: 30,
            fontSize: isSmallScreen ? 28 : 32,
          }}
        >
          OUR ACHIEVEMENTS
        </h4>
      </Grid>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ padding: isSmallScreen && 10 }}
      >
        {achievments.map((achievment, index) => (
          <Grid item xs={6} md={2} key={index}>
            <Box
              style={{
                maxWidth: "100%",
                display: "flex",
              }}
            >
              <img
                alt="achivements"
                src={achievment.image}
                style={{
                  width: "100%",
                  height: "auto",
                }}
              />
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              sx={{ fontSize: { xs: 16, md: 20 } }}
            >
              <h4
                style={{
                  fontFamily: "AdorHairline-Bold",
                  fontWeight: "bold",
                }}
              >
                {rows[achievment.backendMap]} {achievment.unit}
              </h4>
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
export default memo(Achievments);
