import {
  Button,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router";

const CategoryItem = ({ subItem, subIndex }) => {
  const navigate = useNavigate();
  const [opensubcategories, setOpenSubCategories] = useState(false);

  return (
    <React.Fragment key={subIndex}>
      <div style={{ display: "flex", columnGap: 10, justifyContent: "start" }}>
        <Button
          sx={{
            color: "#fff",
            padding: 0,
            fontSize: "1rem",
          }}
          onClick={() => navigate(subItem.navigateto)}
        >
          {subItem.item} <span style={{ color: "#616161" }}>...</span>
        </Button>

        {subItem.subcategories.length > 0 && (
          <Typography
            style={{ color: "white", marginRight: 20, cursor: "pointer" }}
            onClick={() => setOpenSubCategories(!opensubcategories)}
          >
            {opensubcategories ? "-" : "+"}
          </Typography>
        )}
      </div>

      {opensubcategories &&
        subItem.subcategories.map((item, index) => (
          <>
            <Button
              sx={{
                color: "#fff",
                padding: 0,
                fontSize: "0.9rem",
              }}
              onClick={() => navigate(item.navigateto)}
            >
              {"- " + item.item} <span style={{ color: "#616161" }}>....</span>
            </Button>{" "}
            <br />
          </>
        ))}
      <br />
    </React.Fragment>
  );
};
export default CategoryItem;
