import AchievmentCard from "./Components/AchievmentCard/AchievmentCard";
import factory from "../../../../assets/images/1.png";
import capaciy from "../../../../assets/images/2.png";
import staff from "../../../../assets/images/3.png";
import projects from "../../../../assets/images/4.png";
import { Grid } from "@mui/material";
import axios from "axios";
import { API_URL } from "../../../../api";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

const achievments = [
  {
    image: factory,
    value: "10,000",
    title: "FACTORY TOTAL AREA",
    unit: "M²",
    backendMap: "factoryTotalArea",
  },
  {
    image: capaciy,
    value: "15,000",
    title: "PRODUCTION CAPACITY",
    unit: "TONS",
    backendMap: "productionCapacity",
  },
  {
    image: staff,
    value: "120",
    title: "STAFF PERSONNEL",
    unit: "",
    backendMap: "staffPersonnel",
  },
  {
    image: projects,
    value: "200",
    title: "PROJECTS CO",
    unit: <sup>+</sup>,
    backendMap: "projectsCo",
  },
];
const Achievments = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowIndex, setRowIndex] = useState(0);
  const navigate = useNavigate();

  const refetch = () => {
    setLoading(true);
    axios
      .get(`${API_URL}/achievements/`)
      .then((response) => {
        setLoading(false);
        setRows(response.data[0]);
        setRowIndex(response.data[0].id);
      })
      .catch();
  };

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("currentUser");
    !isLoggedIn ? navigate("/admin") : refetch();
  }, []);
  return (
    <Grid container spacing={1}>
      {achievments.map((achievment, index) => (
        <Grid item key={index} xs={12} sm={6} md={3}>
          <AchievmentCard
            achievment={achievment}
            value={rows[achievment.backendMap]}
            refetch={refetch}
            rowIndex={rowIndex}
          />
        </Grid>
      ))}
    </Grid>
  );
};
export default Achievments;
