import React from "react";

const Mailto = ({ emails, children, textDecoration = "none" }) => {
  return (
    <a
      href={`mailto:${emails}`}
      style={{
        textDecoration: textDecoration,
        textDecorationColor: "black",
        color: "black",
      }}
    >
      {children}
    </a>
  );
};

export default Mailto;
