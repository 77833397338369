import { Box, Button, Dialog, Grid, TextField } from "@mui/material";
import "../../../../CommonCss/styles.css";
import ProjectCard from "./Components/ProjectCard/ProjectCard";
import { useEffect, useState } from "react";
import AddorEditProject from "./Components/AddorEditProject/AddorEditProject";
import axios from "axios";
import { API_URL } from "../../../../api";
import { useNavigate } from "react-router";

const Projects = () => {
  const [openAddProject, setOpenAddProject] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const refetch = () => {
    setLoading(true);
    axios
      .get(`${API_URL}/projects/`)
      .then((response) => {
        setLoading(false);
        setRows(response.data);
        setFilteredData(response.data);
      })
      .catch();
  };

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("currentUser");
    !isLoggedIn ? navigate('/admin') : refetch();
  }, []);

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);

    const filtered = rows.filter(
      (item) =>
        item.title.toLowerCase().includes(searchTerm) ||
        item.category.toLowerCase().includes(searchTerm) ||
        item.type.toLowerCase().includes(searchTerm)
    );

    setFilteredData(filtered);
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "flex-start", md: "flex-end" },
          flexDirection: "row",
          columnGap: 2,
        }}
      >
        <Button
          variant="outlined"
          style={{ color: "black", borderColor: "black" }}
          onClick={() => setOpenAddProject(true)}
        >
          Add
        </Button>
      </Box>
      <TextField
        placeholder="Search..."
        value={searchTerm}
        onChange={handleSearch}
        style={{ width: "50%", marginTop: 10 }}
      />
      <Box
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          marginTop: 40,
          paddingTop: 60,
          flexDirection: "column",
          margin: 0,
        }}
        sx={{
          // backgroundColor: "#212121",
          paddingBottom: { sx: 10, md: 10 },
        }}
      >
        <Grid
          container
          spacing={4}
          direction="row"
          justifyContent="center"
          // alignItems="center"
          // sx={{
          //   paddingLeft: { xs: 0, md: 28 },
          //   paddingRight: { xs: 0, md: 28 },
          // }}
        >
          {filteredData.map((project) => (
            <ProjectCard project={project} refetch={refetch} />
          ))}
        </Grid>
        <Dialog onClose={() => setOpenAddProject(false)} open={openAddProject}>
          <AddorEditProject
            refetch={refetch}
            setOpenAddProject={setOpenAddProject}
          />
        </Dialog>
      </Box>
    </>
  );
};
export default Projects;
