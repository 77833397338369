import { useEffect, useState, memo } from "react";
import home_1 from "../../../../assets/images/HomeMain.webp";
import home_1_mob from "../../../../assets/images/home1_mob.webp";
import HeaderBar from "../../../../CommonComponents/HeaderBar/HeaderBar";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";

const MainHeader = ({ handleImageLoad }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [imageLoaded, setImageLoaded] = useState([false, false, false]);

  const image = isSmallScreen ? home_1_mob : home_1;

  const handleLogoLoad = (index) => {
    const updatedImageLoaded = [...imageLoaded];
    updatedImageLoaded[index] = true;
    setImageLoaded(updatedImageLoaded);
  };

  const [breakPoint, setBreakPoint] = useState(window.innerWidth <= 1422);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 1422) setBreakPoint(true);
      else {
        setBreakPoint(false);
      }
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <link rel="preload" as="image" href={image} />
      <div style={{ position: "relative", maxWidth: "100%" }}>
        <div style={{ overflow: "hidden" }}>
          <img
            alt="home"
            src={image}
            style={{ width: "100%" }}
            onLoad={handleImageLoad}
            loading={!isSmallScreen && "lazy"}
          />
        </div>
        <div
          style={{
            position: "absolute",
            top: "0%",
            paddingLeft: breakPoint ? "3%" : "10%",
            color: "black",
            paddingBottom: "1%",
            backgroundColor: "black",
            fontFamily: "AdorHairline-Bold",
            maxWidth: "100%",
            overflowX: "hidden",
          }}
        >
          <HeaderBar handleLogoLoad={handleLogoLoad} />
        </div>
      </div>
    </>
  );
};

export default memo(MainHeader);
