import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  Divider,
  CircularProgress,
} from "@mui/material";
import { useEffect, useState } from "react";
import { API_URL } from "../../../../../../api";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const customTextFieldStyling = {
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "black",
  },
};

const AddFeaturedProducts = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);

    const filtered = rows.filter((item) =>
      item?.name?.toLowerCase().includes(searchTerm)
    );
    setFilteredData(filtered);
  };

  const refetch = () => {
    setLoading(true);
    axios
      .get(`${API_URL}/products/`)
      .then((response) => {
        setLoading(false);
        setRows(response.data);
        setFilteredData(response.data);
      })
      .catch();
  };
  useEffect(() => {
    refetch();
  }, []);
  const handleFeatureProduct = (product) =>
    axios
      .patch(`${API_URL}/products/${product.id}`, { is_best_seller: true })
      .then(() => {
        refetch();
      })
      .catch((err) => {});

  return loading ? (
    <Box
      style={{
        color: "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 40,
      }}
    >
      <CircularProgress
        style={{
          color: "black",
        }}
      />
    </Box>
  ) : (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        rowGap: 10,
        padding: 10,
      }}
    >
      <TextField
        placeholder="Search by name"
        value={searchTerm}
        onChange={handleSearch}
        style={{ marginTop: 10, marginBottom: 20 }}
        sx={customTextFieldStyling}
      />
      {filteredData.map((product, index) => (
        <>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  backgroundColor: "#ECECEC",
                }}
                sx={{ ml: { xs: 2 } }}
              >
                <img
                  src={product?.images && product?.images[0]?.image}
                  style={{
                    width: "70%",
                    maxHeight: "100%",
                  }}
                  alt={product.name}
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Typography
                sx={{
                  fontSize: { xs: 13, md: 15 },
                  marginTop: { md: "20%" },
                }}
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: 700,
                  color: "#000000",
                }}
              >
                {product.name}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              {product.is_best_seller ? (
                <Typography
                  sx={{
                    fontSize: { xs: 13, md: 15 },
                  }}
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: 700,
                    color: "grey",
                  }}
                >
                  Best seller
                </Typography>
              ) : (
                <Button
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: 700,
                    color: "black",
                    marginTop: 20,
                  }}
                  onClick={() => handleFeatureProduct(product)}
                >
                  Add
                </Button>
              )}
            </Grid>
          </Grid>
          <Divider />
        </>
      ))}
    </Box>
  );
};
export default AddFeaturedProducts;
