import machine1 from "../../../../../../assets/images/machines/machine1.webp";
import machine2 from "../../../../../../assets/images/machines/machine2.webp";
import machine3 from "../../../../../../assets/images/machines/machine3.webp";
import machine4 from "../../../../../../assets/images/machines/machine4.webp";
import machine5 from "../../../../../../assets/images/machines/machine5.webp";
import machine6 from "../../../../../../assets/images/machines/machine6.webp";
import machine7 from "../../../../../../assets/images/machines/machine7.webp";
import machine8 from "../../../../../../assets/images/machines/machine8.webp";
import machine9 from "../../../../../../assets/images/machines/machine9.webp";
import machine10 from "../../../../../../assets/images/machines/machine10.webp";
import machine11 from "../../../../../../assets/images/machines/machine11.webp";
import machine12 from "../../../../../../assets/images/machines/machine12.webp";
import machine13 from "../../../../../../assets/images/machines/machine13.webp";
import machine14 from "../../../../../../assets/images/machines/machine14.webp";
import machine15 from "../../../../../../assets/images/machines/machine15.webp";

export const machines = [
  {
    image: machine1,
    title: "Laser Metal Cutting",
    number: "01",
    subheader: "2mX6m",
  },
  {
    image: machine2,
    title: "Waterjet Machine",
    number: "02",
    subheader: "",
  },
  {
    image: machine3,
    title: "Plasma Cutting Machin",
    number: "03",
    subheader: "",
  },
  {
    image: machine4,
    title: "CNC Router Machine",
    number: "04",
    subheader: "",
  },
  {
    image: machine5,
    title: "Laser Tube Machine",
    number: "05",
    subheader: "",
  },
  {
    image: machine6,
    title: "Punching Machine",
    number: "06",
    subheader: "",
  },
  {
    image: machine7,
    title: "Wire Bending Machine",
    number: "07",
    subheader: "",
  },
  {
    image: machine8,
    title: "Hydraulic Press Brake Machine",
    number: "08",
    subheader: "",
  },
  {
    image: machine9,
    title: "Hydraulic Guillotine Shearing Machine",
    number: "09",
    subheader: "",
  },
  {
    image: machine10,
    title: "CNC Tube Bending Machine",
    number: "10",
    subheader: "",
  },
  {
    image: machine11,
    title: "Three Roller Tube Bending Machine",
    number: "11",
    subheader: "",
  },
  {
    image: machine12,
    title: "Four Roller Sheet Machine",
    number: "12",
    subheader: "",
  },
  {
    image: machine13,
    title: "MIG & TIG Welding Machine",
    number: "13",
    subheader: "",
  },
  {
    image: machine14,
    title: "Laser Welding Machine",
    number: "14",
    subheader: "2mX6m",
  },
  {
    image: machine15,
    title: "Tape Edge Banding Machine",
    number: "15",
    subheader: "2mX6m",
  },
];
