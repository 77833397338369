import { Grid, Typography } from "@mui/material";
import logo from "../../assets/images/LogowithText.webp";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import PinterestIcon from "@mui/icons-material/Pinterest";
import { useNavigate } from "react-router";

const getHelpPages = [
  { title: "Contact Us", navigateTo: "/Contact-us", targetId: "" },
  { title: "Privacy Policy", navigateTo: "/privacy-policy", targetId: "" },

];

const ourCompanyPages = [
  { title: "About us", navigateTo: "/", targetId: "about-us" },
];
const ourServices = [
  {
    title: "Hospitality",
    navigateTo: "/Furnishing/Hospitality",
    targetId: "furnishing-projects",
  },
  {
    title: "Mockups",
    navigateTo: "/Furnishing/Mockups",
    targetId: "furnishing-projects",
  },
  {
    title: "Commerical",
    navigateTo: "/Furnishing/Commerical",
    targetId: "furnishing-projects",
  },
  {
    title: "Clubhouse",
    navigateTo: "/Furnishing/Clubhouse",
    targetId: "furnishing-projects",
  },
  {
    title: "Residential",
    navigateTo: "/Furnishing/Residential",
    targetId: "furnishing-projects",
  },
];
const ourProjects = [
  {
    title: "Metal Fabrication",
    navigateTo: "/Metal-Fabrication",
    targetId: "metal-featured-projects",
  },
  {
    title: "Furnishings",
    navigateTo: "/Furnishing",
    targetId: "furnishing-projects",
  },
];
const Footer = ({ top = 30, backgroundColor = "#A99E8D" }) => {
  const navigate = useNavigate();

  const handleClick = (page) => {
    navigate(page?.navigateTo);
    setTimeout(() => {
      const targetElement = document.getElementById(page.targetId);

      if (targetElement) {
        window.scrollTo({
          top: targetElement.offsetTop,
          behavior: "smooth",
        });
      }
    }, 500);
  };
  return (
    <div
      style={{
        position: "relative",
        left: 0,
        bottom: 0,
        top: top,
        width: "100%",
        backgroundColor: backgroundColor,
        flexShrink: 0,
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{ padding: { xs: 0, lg: 8 }, paddingTop: { xs: 5 } }}
      >
        <Grid
          item
          xs={12}
          md={8}
          lg={4}
          sx={{
            alignItems: { xs: "center" },
          }}
          style={{
            display: "flex",
            flexDirection: "column",
            rowGap: 10,
          }}
        >
          <img
            src={logo}
            alt="alfa-logo"
            style={{
              width: "200px",
              height: "auto",
              paddingLeft: 15,
            }}
          />
          <div style={{ display: "flex", flexDirection: "row", columnGap: 10 }}>
            <InstagramIcon
              style={{ color: "black", cursor: "pointer" }}
              onClick={() =>
                window.open(
                  "https://www.instagram.com/alfafurniture.egypt?igsh=MWlobTA1ZDVmOGVweg==",
                  "_blank"
                )
              }
            />
            <FacebookRoundedIcon
              style={{ color: "black", cursor: "pointer" }}
              onClick={() =>
                window.open(
                  "https://www.facebook.com/share/pcGmtBxSYpsVhR4P/?mibextid=eQY6cl",
                  "_blank"
                )
              }
            />
            <TwitterIcon style={{ color: "black" }} />
            <PinterestIcon style={{ color: "black" }} />
          </div>
        </Grid>
        <Grid item xs={12} md={6} lg={2}>
          <Typography
            sx={{
              fontSize: { xs: 16, md: 22 },
            }}
            style={{
              fontFamily: "AdorHairline-Bold",
              fontStyle: "normal",
              fontWeight: "bold",
              textAlign: "center",
              color: "black",
              marginBottom: 2,
            }}
          >
            About Alfa
          </Typography>
          {ourCompanyPages.map((page) => {
            return (
              <Typography
                onClick={() => handleClick(page)}
                sx={{
                  fontSize: { xs: 14, md: 15 },
                }}
                style={{
                  fontFamily: "AdorHairline-Bold",
                  fontStyle: "normal",
                  textAlign: "center",
                  color: "#2f2f2f",
                  marginBottom: 1,
                  cursor: "pointer",
                }}
              >
                {page.title}
              </Typography>
            );
          })}
        </Grid>

        <Grid item xs={12} md={6} lg={2}>
          <Typography
            sx={{
              fontSize: { xs: 16, md: 22 },
            }}
            style={{
              fontFamily: "AdorHairline-Bold",
              fontStyle: "normal",
              textAlign: "center",
              color: "black",
              marginBottom: 2,
            }}
          >
            Sectors{" "}
          </Typography>
          {ourServices.map((page) => {
            return (
              <Typography
                onClick={() => handleClick(page)}
                sx={{
                  fontSize: { xs: 14, md: 15 },
                }}
                style={{
                  fontFamily: "AdorHairline-Bold",
                  fontStyle: "normal",
                  textAlign: "center",
                  color: "#2f2f2f",
                  marginBottom: 3,
                  cursor: "pointer",
                }}
              >
                {page.title}
              </Typography>
            );
          })}
        </Grid>
        <Grid item xs={12} md={6} lg={2}>
          <Typography
            sx={{
              fontSize: { xs: 16, md: 22 },
            }}
            style={{
              fontFamily: "AdorHairline-Bold",
              fontStyle: "normal",
              textAlign: "center",
              color: "black",
              marginBottom: 2,
            }}
          >
            Projects{" "}
          </Typography>
          {ourProjects.map((project) => {
            return (
              <Typography
                onClick={() => handleClick(project)}
                sx={{
                  fontSize: { xs: 14, md: 15, marginBottom: 1 },
                }}
                style={{
                  fontFamily: "AdorHairline-Bold",
                  fontStyle: "normal",
                  textAlign: "center",
                  color: "#2f2f2f",
                  cursor: "pointer",
                }}
              >
                {project.title}
              </Typography>
            );
          })}
        </Grid>

        <Grid item xs={12} md={6} lg={2}>
          <Typography
            sx={{
              fontSize: { xs: 16, md: 22 },
            }}
            style={{
              fontFamily: "AdorHairline-Bold",
              fontStyle: "normal",
              fontWeight: "bold",
              textAlign: "center",
              color: "black",
              marginBottom: 2,
            }}
          >
            Support
          </Typography>
          {getHelpPages.map((page) => {
            return (
              <Typography
                onClick={() => navigate(page.navigateTo)}
                sx={{
                  fontSize: { xs: 14, md: 15, marginBottom: 1 },
                }}
                style={{
                  fontFamily: "AdorHairline-Bold",
                  fontStyle: "normal",
                  textAlign: "center",
                  color: "#2f2f2f",
                  cursor: "pointer",
                }}
              >
                {page.title}
              </Typography>
            );
          })}
        </Grid>
      </Grid>{" "}
    </div>
  );
};
export default Footer;
