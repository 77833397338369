import React, { useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { Form, Formik } from "formik";
import FormField from "../../../../../CommonComponents/FormField";
import axios from "axios";
import { API_URL } from "../../../../../api";
// import { Box, CircularProgress, Divider } from "@mui/material";
import { Box, Button, CircularProgress } from "@mui/material";

const customTextFieldStyling = {
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "black",
  },
};

const AddEmployee = ({ refetch, setOpenAddEmployee }) => {
  const [loading, setLoading] = useState(false);
  const [msg, setMessage] = useState(false);

  const initialValues = useMemo(
    () => ({
      email: "",
      password: "1234",
      name: "",
    }),
    []
  );

  const handleAddAdmin = async (event, values) => {
    event.preventDefault();
    setLoading(true);
    axios
      .post(`${API_URL}/admins/signup/`, values)
      .then((response) => {
        setLoading(false);
        setMessage("Admin added successfully");
        refetch();
        setOpenAddEmployee(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  return (
    <Box
      style={{
        background: "#FFFFFF",
        padding: 50,
        width: "100%",
      }}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          await new Promise((r) => setTimeout(r, 500));
          alert(JSON.stringify(values, null, 2));
        }}
      >
        {({ values }) => (
          <Form>
            <Typography
              style={{ fontWeight: "bold", fontFamily: "Montserrat" }}
            >
              Personal Information
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <FormField
                  type="input"
                  name={`email`}
                  label="Email"
                  variant="standard"
                  fullwidth
                  customizedStyling={customTextFieldStyling}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <FormField
                  type="input"
                  name={`name`}
                  label="Name"
                  variant="standard"
                  fullwidth
                  customizedStyling={customTextFieldStyling}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormField
                  type="date"
                  name={`DOB`}
                  label="Date of birth (Optional)"
                  variant="standard"
                  fullwidth
                  customizedStyling={customTextFieldStyling}
                />
              </Grid>
            </Grid>

            <Button
              style={{ background: "black", color: "white", marginTop: 30 }}
              fullWidth
              onClick={(e) => handleAddAdmin(e, values)}
            >
              {loading ? <CircularProgress style={{ color: "white" }}/> : "Save"}
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
export default AddEmployee;
