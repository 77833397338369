import React, { useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { FieldArray, Form, Formik } from "formik";
import FormField from "../../../../../../CommonComponents/FormField";
import axios from "axios";
import { API_URL } from "../../../../../../api";
import { CircularProgress, Divider } from "@mui/material";
import { Box, Button } from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

const customTextFieldStyling = {
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "black",
  },
};

const AddorEditProject = ({
  isEdit = false,
  project,
  refetch,
  setOpenAddProject,
  setOpenEditProject,
}) => {
  const [loading, setLoading] = useState(false);
  const [msg, setMessage] = useState(false);
  const [error, setError] = useState("");

  const initialValues = useMemo(
    () => ({
      title: isEdit ? project?.title : "",
      category: isEdit ? project?.category : "",
      subCategory: isEdit ? project?.subCategory : "",
      // images: isEdit ? project?.images : [],
      // type: isEdit ? project?.type : "",
      // shortDescription: isEdit ? project?.shortDescription : "",
      // text1: isEdit ? project?.text1 : "",
      // text2: isEdit ? project?.text2 : "",
      // text3: isEdit ? project?.text3 : "",
      // date: isEdit ? project?.date : "",
      // text2SubHeader: isEdit ? project?.text2SubHeader : "",
      image: isEdit ? project?.image : "",
    }),
    []
  );

  const handleAddProdject = async (event, values) => {
    event.preventDefault();
    setLoading(true);
    const changedValues = {};
    Object.keys(values).forEach((key) => {
      if (values[key] !== initialValues[key]) {
        changedValues[key] = values[key];
      }
    });
    if (isEdit) {
      axios
        .patch(`${API_URL}/projects/${project.id}`, changedValues, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setLoading(false);
          setMessage("Project added successfully");
          setOpenEditProject(false);
          refetch();
        })
        .catch((err) => {
          setLoading(false);
          err.message == "Network Error"
            ? setError(
                "Failed to Save ! Your images might be too large, compress them and try again."
              )
            : setError("Failed to Save !");
        });
    } else {
      axios
        .post(`${API_URL}/projects/`, values, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setLoading(false);
          setMessage("Project added successfully");
          setOpenAddProject(false);
          refetch();
        })
        .catch((err) => {
          setLoading(false);
          err.message == "Network Error"
            ? setError(
                "Failed to Save ! Your images might be too large, compress them and try again."
              )
            : setError("Failed to Save !");
        });
    }
  };
  return (
    <Box
      style={{
        background: "#FFFFFF",
        padding: 50,
        width: "100%",
      }}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          await new Promise((r) => setTimeout(r, 500));
          alert(JSON.stringify(values, null, 2));
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Typography
              style={{
                fontWeight: "bold",
                fontFamily: "Montserrat",
                fontSize: 25,
              }}
            >
              project Details
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <FormField
                  type="input"
                  name={`title`}
                  label="Title"
                  variant="standard"
                  fullwidth
                  customizedStyling={customTextFieldStyling}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  type="select"
                  name={`category`}
                  label="category"
                  variant="standard"
                  fullwidth
                  options={[
                    { label: "Metal Fabrication", value: "Metal fabrication" },
                    { label: "Furnishing", value: "Furnishing" },
                  ]}
                  customizedStyling={customTextFieldStyling}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  type="select"
                  name={`subCategory`}
                  label="Sub-Category"
                  variant="standard"
                  fullwidth
                  customizedStyling={customTextFieldStyling}
                  required
                  options={[
                    { label: "Hospitality", value: "Hospitality" },
                    { label: "Mockups", value: "Mockups" },
                    { label: "Commerical", value: "Commerical" },
                    { label: "Clubhouse", value: "Clubhouse" },
                    { label: "Residential", value: "Residential" },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={12} style={{ marginTop: 30 }}>
                <input
                  type="file"
                  onChange={(event) => {
                    setFieldValue("image", event.currentTarget.files[0]);
                  }}
                />
              </Grid>
            </Grid>
            {error && (
              <Typography
                style={{ marginTop: 3, marginBottom: 3, color: "red" }}
              >
                {error}
              </Typography>
            )}
            <Button
              style={{ background: "black", color: "white", marginTop: 30 }}
              fullWidth
              onClick={(e) => handleAddProdject(e, values)}
            >
              {loading ? (
                <CircularProgress style={{ color: "white" }} />
              ) : (
                "Save"
              )}
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
export default AddorEditProject;
