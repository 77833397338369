import HeaderBar from "../../../../CommonComponents/HeaderBar/HeaderBar";
import { useEffect, useState } from "react";

const MainHeader = ({ imageRemoved }) => {
  const [breakPoint, setBreakPoint] = useState(window.innerWidth <= 1422);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 1422) setBreakPoint(true);
      else {
        setBreakPoint(false);
      }
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <div style={{ position: "relative", maxWidth: "100%" }}>
        <div
          style={{
            top: "0%",
            paddingLeft: breakPoint ? "3%" : "10%",
            color: "white",
            paddingBottom: "1%",
            backgroundColor: "black",
            fontFamily: "AdorHairline-Bold",
            maxWidth: "100%",
            overflowX: "hidden",
          }}
        >
          <HeaderBar imageRemoved={imageRemoved} />
        </div>
      </div>
    </>
  );
};
export default MainHeader;
