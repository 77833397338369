import { Box, Divider, Grid } from "@mui/material";
import electrostaticHeader from "../../../../assets/images/coating_home.jpg";

const Main = () => {
  return (
    <Box
      sx={{ paddingLeft: { xs: 2, sm: 15 }, paddingRight: { xs: 2, sm: 40 } }}
    >
      <Grid
        container
        spacing={2}
        direction="row"
        sx={{ paddingTop: { xs: 2, sm: 5 } }}
      >
        <Grid item xs={12} md={12} sx={{ fontSize: { xs: 15, sm: 17 } }}>
          <h1 style={{ fontFamily: "AdorHairline-Bold" }}>
            Alfa Electrostatic Powder Coating
          </h1>
        </Grid>
      </Grid>
      <Divider
        style={{
          backgroundColor: "#A8A194",
          width: 270,
          padding: 1,
          marginTop: 20,
          marginBottom: 50,
        }}
      />
      <h2
        style={{
          fontWeight: 300,
          fontFamily: "AdorHairline-light",
          fontSize: 20,
        }}
      >
        Alfa electrostatic powder coating line offers a high-quality paint
        solution. Our automated system provides an efficient coating process to
        ensure the finest coating for aluminum, stainless steel and carbon steel
        material. Our pre-treatment phase includes a six-stage wash system,
        assuring superior performance and an increased lifespan for the final
        product. After we dry the material and preheat the parts, they're ready
        for powder coating application.
      </h2>
      <br></br>
      <h2
        style={{
          fontWeight: 300,
          fontFamily: "AdorHairline-light",
          fontSize: 20,
        }}
      >
        We source only the finest powder from reputable suppliers for flawless
        powder coating application which is applied in our spray booth using
        both automatic and manual methods to achieve a consistent and
        high-quality coating every time. The material is heated to a calculated
        temperature and duration based on the chemical composition of the powder
        and the coating density. Next, the powder undergoes a melting procedure
        that yields a superior finish, ensuring the retention of the part's
        visual allure and maintaining its characteristics long after it has been
        dispatched to our valued customers.
      </h2>

      <Grid container spacing={3} sx={{ marginTop: { xs: 1, sm: 4 } }}>
        <Grid item xs={12} sm={8}>
          <Box
            style={{
              maxWidth: "100%",
              display: "flex",
            }}
          >
            <img
              alt="electrostatic"
              src={electrostaticHeader}
              style={{
                width: "100%",
                height: "auto",
              }}
            />
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            fontSize: { xs: 13, sm: 17 },
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          <h3 style={{ fontFamily: "AdorHairline-Bold", fontSize: 34 }}>
            Capabilities{" "}
          </h3>
          <Divider
            style={{
              backgroundColor: "#A8A194",
              width: 130,
              padding: 1,
              marginTop: 10,
              marginBottom: 15,
            }}
          />
          <h4
            style={{
              fontWeight: 100,
              fontFamily: "AdorHairline-light",
              fontSize: 20,
            }}
          >
            235-meter automated line
          </h4>
          <h4
            style={{
              fontWeight: 300,
              fontFamily: "AdorHairline-light",
              fontSize: 20,
            }}
          >
            6 pre-treatment stages
          </h4>
          <h4
            style={{
              fontWeight: 300,
              fontFamily: "AdorHairline-light",
              fontSize: 20,
            }}
          >
            Dry-off and curing ovens
          </h4>
          <h4
            style={{
              fontWeight: 300,
              fontFamily: "AdorHairline-light",
              fontSize: 20,
            }}
          >
            Automated coating booth
          </h4>
          <h4
            style={{
              fontWeight: 300,
              fontFamily: "AdorHairline-light",
              fontSize: 20,
            }}
          >
            Straight off the line inspection and packaging
          </h4>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Main;
