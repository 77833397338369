import { Box } from "@material-ui/core";
import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Footer from "../../CommonComponents/Footer";
import MainHeader from "../Contact/Components/MainHeader/MainHeader/MainHeader";

const Confirmation = () => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };
  useEffect(() => {
    document.title = "ALFA - Confirmation";
    return () => {
      document.title = "ALFA - Metal Fabrication";
    };
  }, []);
  return (
    <div style={{ backgroundColor: "black" }}>
      <MainHeader handleImageLoad={handleImageLoad} />
      {imageLoaded && (
        <Box
          sx={{
            paddingTop: { xs: 7, md: 20 },
            paddingBottom: { xs: 7, md: 20 },
            display: "flex",
            flexDirection: "column",
            rowGap: 10,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h2"
            style={{ color: "white", fontFamily: "AdorHairline-Bold" }}
          >
            Thank you for contacting us
          </Typography>
          <Typography
            variant="h4"
            style={{ color: "white", fontFamily: "AdorHairline-Bold" }}
          >
            Our team will get back to you shortly.
          </Typography>
        </Box>
      )}
      <Footer top={70} />
    </div>
  );
};
export default Confirmation;
