import { Box, Button, Grid, Typography } from "@mui/material";
import FormField from "../../../../../../CommonComponents/FormField";
import { Form, Formik } from "formik";
import { useMemo, useState } from "react";
import axios from "axios";
import { API_URL } from "../../../../../../api";

const EditAchievment = ({
  achievment,
  refetch,
  value,
  setOpenEditAchievment,
  rowIndex,
}) => {
  const [loading, setLoading] = useState(false);

  const initialValues = useMemo(
    () => ({
      value: value,
    }),
    [achievment]
  );
  const handleEditClient = async (event, values) => {
    setLoading(true);
    values = { [achievment.backendMap]: values.value };
    axios
      .patch(`${API_URL}/achievements/${rowIndex}`, values, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        setOpenEditAchievment(false);
        setLoading(false);
        refetch();
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  return (
    <Box
      style={{
        background: "#FFFFFF",
        padding: 40,
        width: "100%",
      }}
    >
      {" "}
      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          await new Promise((r) => setTimeout(r, 500));
          alert(JSON.stringify(values, null, 2));
        }}
      >
        {({ values }) => (
          <Form>
            <Typography
              style={{
                fontWeight: "bold",
                fontFamily: "Montserrat",
                marginBottom: 25,
              }}
            >
              EDIT {achievment.title}
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <FormField
                  type="input"
                  name={`value`}
                  label="Value"
                  variant="outlined"
                  fullwidth
                />
              </Grid>
            </Grid>

            <Button
              onClick={(event) => handleEditClient(event, values)}
              variant="contained"
              style={{
                backgroundColor: "black",
                fontFamily: "Montserrat",
                borderColor: "black",
                marginTop: 50,
                marginBottom: 50,
                marginLeft: 10,
              }}
            >
              Save
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
export default EditAchievment;
