import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  Grid,
} from "@mui/material";
import "../../../../../../CommonCss/styles.css";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import AddorEditProject from "../AddorEditProject/AddorEditProject";
import { useState } from "react";
import { API_URL } from "../../../../../../api";
import axios from "axios";
import ConfirmDelete from "../../../../../../CommonComponents/ConfirmDelete";
import DeleteIcon from "@mui/icons-material/Delete";

const ProjectCard = ({ project, refetch }) => {
  const navigate = useNavigate();
  const [currentRow, setCurrentRow] = useState("");
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const [openEditProject, setOpenEditProject] = useState(false);

  const handleDeleteProject = () =>
    axios
      .delete(`${API_URL}/projects/${currentRow.id}`)
      .then(() => {
        setOpenConfirmDelete(false);
        refetch();
      })
      .catch((err) => {});
  return (
    <Grid item xs={11} sm={6} md={4}>
      <Card
        sx={{
          maxWidth: 400,
          boxShadow: "none",
          borderRadius: 0,
        }}
      >
        <CardMedia component="img" height="250" image={project?.image} alt="" />
        <CardContent
          style={{ paddingLeft: 0, display: "flex", flexDirection: "row" }}
        >
          <h1
            style={{
              fontFamily: "AdorHairline-Bold",
              fontSize: 20,
              color: "black",
            }}
          >
            {project.title}{" "}
            <span
              onClick={() => setOpenEditProject(true)}
              style={{ cursor: "pointer" }}
            >
              <EditIcon />
            </span>
          </h1>
          <Button
            onClick={() => {
              setOpenConfirmDelete(true);
              setCurrentRow(project);
            }}
          >
            <DeleteIcon style={{ color: "red" }} />
          </Button>
        </CardContent>
      </Card>
      <Dialog onClose={() => setOpenEditProject(false)} open={openEditProject}>
        <AddorEditProject
          isEdit
          project={project}
          setOpenEditProject={setOpenEditProject}
          refetch={refetch}
        />
      </Dialog>
      <Dialog
        onClose={() => setOpenConfirmDelete(false)}
        open={openConfirmDelete}
      >
        <ConfirmDelete
          onClose={() => setOpenConfirmDelete(false)}
          text={currentRow.title}
          onDelete={(e) => handleDeleteProject(e)}
        />
      </Dialog>
    </Grid>
  );
};
export default ProjectCard;
